import { FormEventHandler, useCallback, useState } from "react";
import Controls from "./Controls";
import styles from "./ZipCode.module.scss";
import Checkbox from "./shared/Checkbox";
import Layout from "./shared/Layout";
import TextInput from "./shared/TextInput";
import { useQuestions } from "../../contexts/QuestionContext";

type ZipCodeProps = {
	label: string;
	outsideLabel: string;
	outsideValueAlias: string;
	outsideValue: string;
	index: number;
};
export default function ZipCode({
	label,
	index,
	outsideLabel,
	outsideValueAlias,
	outsideValue,
	...inputProps
}: ZipCodeProps) {
	const { answers, saveAnswer } = useQuestions();
	const [value, setValue] = useState<string>(answers[index] || "");
	const [checked, setChecked] = useState(answers[index] === outsideValueAlias);
	const [valid, setValid] = useState(checked);

	const onCheckboxChange = useCallback<FormEventHandler<HTMLInputElement>>(
		(event) => {
			setChecked(event.currentTarget.checked);
			setValue(event.currentTarget.checked ? outsideValueAlias : "");
		},
		[outsideValueAlias]
	);

	const mergedInputProps = {
		validityMessages: {
			patternMismatch: "Please enter a valid ZIP code (eg. 90210)",
		},
		...inputProps,
	};

	return (
		<Layout
			textInput={true}
			label={label}
			controls={
				<Controls
					className={styles.controls}
					canProceed={valid || checked}
					onNext={(next) => {
						saveAnswer(index, value);
						next();
					}}
				/>
			}
		>
			<TextInput
				{...mergedInputProps}
				type="text"
				disabled={value === outsideValueAlias}
				value={value}
				forceValid={checked}
				onInput={(event) => setValue(event.currentTarget.value)}
				onValid={setValid}
				pattern={!checked ? "[0-9]{5}" : undefined}
			/>
			<Checkbox
				label={outsideLabel}
				value={outsideValueAlias}
				checked={checked}
				onChange={onCheckboxChange}
			/>
		</Layout>
	);
}
