import imageA from "../assets/instruction-a.jpg";
import Button from "../components/Button";
import { useAppMode } from "../contexts/AppContext";
import { useFullScreen } from "../utils/useFullScreen";
import styles from "./Instruction.module.scss";

function Instruction() {
	const appMode = useAppMode();
	const onStartClick = useFullScreen("../age");

	return (
		<div className={styles.main}>
			<div className={styles.heading}>
				<h1>
					<Heading />
				</h1>
			</div>
			<div className={styles.image}>
				<img src={imageA} alt="" />
			</div>
			<div className={styles.action}>
				<h2>Stand on the black footprints</h2>
				{appMode === "kiosk" && (
					<Button onClick={onStartClick} className={styles.button}>
						Start
					</Button>
				)}
			</div>
		</div>
	);
}

export default Instruction;

export const Heading = () => {
	return (
		<svg viewBox="0 0 984 86" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M180.681 1.16H194.651L199.821 53.08L204.661 1.16H218.741L208.291 83H191.681L187.391 38.01L183.101 83H165.831L155.601 1.16H170.781L175.401 53.63L180.681 1.16ZM220.54 1.16H251.12V15.57H235.17V33.39H247.71V48.13H235.17V68.26H251.89V83H220.54V1.16ZM254.7 1.16H269.33V68.26H284.84V83H254.7V1.16ZM309.687 64.96V46.92H324.207V65.4C324.207 75.19 320.247 84.32 307.267 84.32H304.297C290.217 84.32 287.027 75.19 287.027 64.96V18.76C287.027 8.86 291.317 0.169998 304.297 0.169998H307.267C321.237 0.169998 324.207 8.86 324.207 18.21V31.96H309.687V18.76C309.687 15.68 308.697 14.03 305.947 14.03C303.197 14.03 302.317 15.57 302.317 18.76V64.96C302.317 68.15 303.417 69.91 306.057 69.91C308.697 69.91 309.687 67.93 309.687 64.96ZM365.915 20.96V64.3C365.915 76.29 359.535 84.21 348.535 84.21H345.235C333.685 84.21 327.525 77.06 327.525 64.3V21.07C327.525 8.42 332.475 0.169998 345.125 0.169998H348.425C361.295 0.169998 365.915 8.42 365.915 20.96ZM350.625 64.74V19.86C350.625 16.23 349.415 14.47 346.775 14.47C343.915 14.47 342.815 16.23 342.815 19.86V64.74C342.815 67.27 343.805 69.58 346.885 69.58C349.855 69.58 350.625 67.27 350.625 64.74ZM369.749 83V1.16H387.789L393.399 42.52L399.009 1.16H415.839V83H402.639V34.6L395.379 83H389.879L382.509 34.6V83H369.749ZM420.023 1.16H450.603V15.57H434.653V33.39H447.193V48.13H434.653V68.26H451.373V83H420.023V1.16ZM464.032 1.16H499.562V15.57H489.112V83H474.482V15.57H464.032V1.16ZM539.831 20.96V64.3C539.831 76.29 533.451 84.21 522.451 84.21H519.151C507.601 84.21 501.441 77.06 501.441 64.3V21.07C501.441 8.42 506.391 0.169998 519.041 0.169998H522.341C535.211 0.169998 539.831 8.42 539.831 20.96ZM524.541 64.74V19.86C524.541 16.23 523.331 14.47 520.691 14.47C517.831 14.47 516.731 16.23 516.731 19.86V64.74C516.731 67.27 517.721 69.58 520.801 69.58C523.771 69.58 524.541 67.27 524.541 64.74Z"
				fill="white"
			/>
			<path
				d="M569.024 84.1H567.924C559.344 84.1 554.614 76.73 554.614 68.04V19.31C554.614 9.19 558.794 0.169998 571.664 0.169998H574.634C589.044 0.169998 591.574 9.41 591.574 19.2V28.22H577.274V19.09C577.274 15.79 576.504 13.92 573.534 13.92C571.004 13.92 569.904 15.68 569.904 19.09V64.63C569.904 68.15 571.334 69.91 573.864 69.91C576.614 69.91 578.154 68.15 578.154 64.85V47.25H572.764V33.5H592.234V83H583.324C582.884 81.13 582.004 77.72 581.124 75.19C579.804 77.94 576.284 84.1 569.024 84.1ZM618.197 83L616.657 69.25H607.857L606.537 83H593.557L603.897 1.16H621.277L632.387 83H618.197ZM612.147 27.12L609.287 55.17H615.227L612.147 27.12ZM634.007 83V1.16H652.047L657.657 42.52L663.267 1.16H680.097V83H666.897V34.6L659.637 83H654.137L646.767 34.6V83H634.007ZM684.28 1.16H714.86V15.57H698.91V33.39H711.45V48.13H698.91V68.26H715.63V83H684.28V1.16ZM767.78 20.96V64.3C767.78 76.29 761.4 84.21 750.4 84.21H747.1C735.55 84.21 729.39 77.06 729.39 64.3V21.07C729.39 8.42 734.34 0.169998 746.99 0.169998H750.29C763.16 0.169998 767.78 8.42 767.78 20.96ZM752.49 64.74V19.86C752.49 16.23 751.28 14.47 748.64 14.47C745.78 14.47 744.68 16.23 744.68 19.86V64.74C744.68 67.27 745.67 69.58 748.75 69.58C751.72 69.58 752.49 67.27 752.49 64.74ZM771.614 1.16H785.364L795.374 42.96V1.16H808.354V83H795.924L784.594 38.12V83H771.614V1.16ZM825.742 67.16V83H812.652V67.16H825.742ZM811.222 1.16H827.172L822.002 64.63H816.392L811.222 1.16Z"
				fill="white"
				fillOpacity="0"
			/>
			<path
				d="M826.127 67.9902V83.8302H813.037V67.9902H826.127ZM811.607 1.99023H827.557L822.387 65.4602H816.777L811.607 1.99023Z"
				fill="white"
			/>
			<path
				d="M772 1.99023H785.75L795.76 43.7902V1.99023H808.74V83.8302H796.31L784.98 38.9502V83.8302H772V1.99023Z"
				fill="white"
			/>
			<path
				d="M768.165 21.79V65.13C768.165 77.12 761.785 85.04 750.785 85.04H747.485C735.935 85.04 729.775 77.89 729.775 65.13V21.9C729.775 9.25 734.725 1 747.375 1H750.675C763.545 1 768.165 9.25 768.165 21.79ZM752.875 65.57V20.69C752.875 17.06 751.665 15.3 749.025 15.3C746.165 15.3 745.065 17.06 745.065 20.69V65.57C745.065 68.1 746.055 70.41 749.135 70.41C752.105 70.41 752.875 68.1 752.875 65.57Z"
				fill="white"
			/>
			<path
				d="M684.666 1.99023H715.246V16.4002H699.296V34.2202H711.836V48.9602H699.296V69.0902H716.016V83.8302H684.666V1.99023Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M647.145 1.99023H634.393V83.8302L647.145 83.8302V1.99023Z"
				fill="#DBFF00"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M652.433 1.99023H647.145V83.8302L647.153 83.8302V35.4302L654.523 83.8302H660.023L667.283 35.4302V83.8302H680.483V1.99023H663.653L658.043 43.3502L652.433 1.99023Z"
				fill="white"
			/>
			<path
				d="M618.582 83.8302L617.042 70.0802H608.242L606.922 83.8302H593.942L604.282 1.99023H621.662L632.772 83.8302H618.582ZM612.532 27.9502L609.672 56.0002H615.612L612.532 27.9502Z"
				fill="#DBFF00"
			/>
			<path
				d="M569.41 84.93H568.31C559.73 84.93 555 77.56 555 68.87V20.14C555 10.02 559.18 1 572.05 1H575.02C589.43 1 591.96 10.24 591.96 20.03V29.05H577.66V19.92C577.66 16.62 576.89 14.75 573.92 14.75C571.39 14.75 570.29 16.51 570.29 19.92V65.46C570.29 68.98 571.72 70.74 574.25 70.74C577 70.74 578.54 68.98 578.54 65.68V48.08H573.15V34.33H592.62V83.83H583.71C583.27 81.96 582.39 78.55 581.51 76.02C580.19 78.77 576.67 84.93 569.41 84.93Z"
				fill="white"
			/>
		</svg>
	);
};
