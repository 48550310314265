// import pino, { Logger } from "pino";
import { Logtail } from "@logtail/browser";
import { logtailToken } from "../config";
import { consola } from "consola";

const logtail = logtailToken ? new Logtail(logtailToken) : undefined;
export let globalContext: Record<string, any> = {};
export function setLoggerContext(context: Record<string, any>) {
	globalContext = { ...globalContext, ...context };
}
export function toggleLoggerLevel(debug: boolean) {
	// "log" | "debug"
	consola.level = debug ? 4 : 2;
}

consola.addReporter({
	log(object) {
		const { type, args: logArgs } = object;
		const [msg, ...args] = logArgs;
		logtail?.log(msg, type, { args, ...globalContext });
	},
});

// @ts-ignore
window.consola = consola;

export default consola;
