import { useCallback, useEffect } from "react";

export function useEnterKey(enabled = false, callback: () => void) {
	const handleKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if (!enabled) return;
			if (event.key === "Enter") {
				event.preventDefault();
				callback();
			}
		},
		[callback, enabled]
	);

	useEffect(() => {
		window.addEventListener("keydown", handleKeyDown);
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [handleKeyDown]);
}
