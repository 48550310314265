import {
	createContext,
	useEffect,
	useState,
	useContext,
	useRef,
	useCallback,
} from "react";
import Face from "../utils/Face";
import CaptureContext from "./CaptureContext";

const FaceContext = createContext({});

export default FaceContext;

export function FaceHandler({ children }) {
	const { getVideo, init } = useContext(CaptureContext);
	const faceDetect = useRef(null);
	const predictWebcam = useRef(null);
	const getRotation = useRef(null);
	const face = useRef(null);
	const [initialized, setInitialized] = useState(false);

	const doInit = useCallback(async () => {
		face.current = new Face(getVideo());
		await face.current.init();

		faceDetect.current = face.current.faceDetect;
		predictWebcam.current = face.current.predictWebcam;
		getRotation.current = face.current.getRotation;
		setInitialized(true);
	}, [getVideo]);

	useEffect(() => {
		if (!init) return;
		doInit();
	}, [doInit, init]);

	return (
		<FaceContext.Provider
			value={{
				face,
				faceDetect,
				predictWebcam,
				getRotation,
				initialized,
			}}
		>
			{children}
		</FaceContext.Provider>
	);
}

export function useFace() {
	return useContext(FaceContext);
}
