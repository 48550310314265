import styles from "./NotFound.module.scss";

export default function NotFound() {
	return (
		<div className={styles.main}>
			<div className={styles.content}>
				<h1>Page not found</h1>
			</div>
		</div>
	);
}
