import { Result } from "../types";
import logger from "./logger";

export const callRemote = async <T>(url: string, options: RequestInit = {}) => {
	try {
		logger.debug(`callRemote::request ${options?.method || "GET"} ${url}`);
		const response = await fetch(url, {
			...options,
		});
		logger.debug(
			`callRemote::response ${options?.method || "GET"} ${url} ${response.status}`
		);

		if (response.status >= 400) {
			throw new Error("callRemote::error", {
				cause: {
					status: response.status,
					message: (await response.text()) ?? response.statusText,
				},
			});
		}

		const body = await response.text();
		return { ok: true, value: body } as Result<T>;
	} catch (e) {
		return {
			ok: false,
			value: new Error(`callRemote::error`, { cause: e }),
		} as Result<never>;
	}
};
