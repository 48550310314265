import cx from "classnames";
import { useCallback, useState } from "react";
import { useQuestions } from "../../contexts/QuestionContext";
import { useEnterKey } from "../../utils/useEnterKey";
import styles from "./Controls.module.scss";
import Button from "./shared/Button";
import Modal from "../ModalPlain";
import TextGrid, { TextGridProps } from "./shared/TextGrid";
import TextList from "./shared/TextList";
import useMatchMedia from "../../utils/useMatchMedia";
/**
 * A function that handles navigation.
 * @param continueNavigation - A function to call to continue with the default navigation behavior.
 * This will either be next or back depending on the context.
 */
export type ControlsNavigationHandler = (
	continueNavigation: () => void
) => void;

type ControlsProps = {
	canProceed?: boolean;
	className?: string;
	onNext?: ControlsNavigationHandler;
	onBack?: ControlsNavigationHandler;
	examples?: string[];
	examplesLabel?: string;
	onExampleSelect?: (value: string) => void;
};

const defaultNavigationHandler: ControlsNavigationHandler = (
	continueNavigation
) => continueNavigation();

function Controls({
	canProceed = false,
	className = "",
	onNext = defaultNavigationHandler,
	onBack = defaultNavigationHandler,
	examples,
	examplesLabel = "A few examples",
	onExampleSelect,
}: ControlsProps) {
	const { step, totalStep, backOneStep, nextOneStep } = useQuestions();
	const canGoBack = step > 0;
	const onNextStep = useCallback(() => {
		onNext(nextOneStep);
	}, [nextOneStep, onNext]);
	const onBackStep = useCallback(() => {
		onBack(backOneStep);
	}, [backOneStep, onBack]);
	const [modalOpen, setModalOpen] = useState(false);
	const isLargeScreen = useMatchMedia("(min-width: 1280px)");

	const [selectedExample, setSelectedExample] = useState("");
	const onExampleClick = useCallback<
		Exclude<TextGridProps["onClick"], undefined>
	>(
		(event) => {
			const value = event.currentTarget.value;
			setSelectedExample(value);
			onExampleSelect?.(value);

			setTimeout(() => {
				setModalOpen(false);
			}, 200);

			setTimeout(() => {
				setSelectedExample("");
			}, 500);
		},
		[onExampleSelect]
	);

	useEnterKey(canProceed, onNextStep);

	return (
		<div className={cx(styles.main, className)}>
			{canGoBack && (
				<button className={styles.back} onClick={onBackStep}>
					<Arrow />
					Back
				</button>
			)}

			<div className={styles.actions}>
				{!!examples?.length && (
					<button
						className={styles.examples}
						onClick={() => setModalOpen(true)}
					>
						See examples
					</button>
				)}
				<Button
					type="submit"
					className={styles.next}
					onClick={onNextStep}
					disabled={!canProceed}
				>
					{step < totalStep - 1 ? "Next" : "Submit"}
				</Button>
			</div>

			{!!examples?.length && (
				<Modal open={modalOpen} onClose={() => setModalOpen(false)}>
					{isLargeScreen && (
						<TextGrid
							options={examples}
							label={examplesLabel}
							value={selectedExample}
							isInModal={true}
							onClick={onExampleClick}
						/>
					)}

					{!isLargeScreen && (
						<TextList
							options={examples}
							label={examplesLabel}
							value={selectedExample}
							isInModal={true}
							onClick={onExampleClick}
						/>
					)}
				</Modal>
			)}
		</div>
	);
}

export default Controls;

function Arrow() {
	return (
		<svg viewBox="0 0 17 9">
			<path d="M15.9629 5C16.239 5 16.4629 4.77614 16.4629 4.5C16.4629 4.22386 16.239 4 15.9629 4L15.9629 5ZM0.609336 4.14645C0.414074 4.34171 0.414074 4.65829 0.609336 4.85355L3.79132 8.03553C3.98658 8.2308 4.30316 8.2308 4.49842 8.03553C4.69369 7.84027 4.69369 7.52369 4.49842 7.32843L1.67 4.5L4.49842 1.67157C4.69369 1.47631 4.69369 1.15973 4.49842 0.964465C4.30316 0.769203 3.98658 0.769203 3.79132 0.964465L0.609336 4.14645ZM15.9629 4L0.96289 4L0.96289 5L15.9629 5L15.9629 4Z" />
		</svg>
	);
}
