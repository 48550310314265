import cx from "classnames";
import { MouseEventHandler } from "react";
import styles from "./TextSelect.module.scss";

export type TextSelectProps = {
	value: string;
	options: { label: string; value: string }[] | string[];
	onClick?: MouseEventHandler<HTMLButtonElement>;
};

export default function Select({
	value,
	options,
	onClick,
	...inputProps
}: TextSelectProps) {
	return (
		<>
			<input {...inputProps} type="hidden" value={value} />
			<div className={styles.options}>
				{options.map((option, index) => {
					const itemLabel = typeof option === "string" ? option : option.label;
					const itemValue = typeof option === "string" ? option : option.value;
					const isSelected = value === itemValue;
					const isDismissed = value && value !== itemValue;

					return (
						<button
							className={cx(styles.option, {
								[styles.selected]: isSelected,
								[styles.dismissed]: isDismissed,
							})}
							value={itemValue}
							key={index}
							onClick={onClick}>
							<span>{itemLabel}</span>
							<Arrow />
						</button>
					);
				})}
			</div>
		</>
	);
}

function Arrow() {
	return (
		<svg viewBox="0 0 29 23">
			<path d="M2 10C1.17157 10 0.5 10.6716 0.5 11.5C0.5 12.3284 1.17157 13 2 13V10ZM28.0607 12.5607C28.6464 11.9749 28.6464 11.0251 28.0607 10.4393L18.5147 0.893398C17.9289 0.307611 16.9792 0.307611 16.3934 0.893398C15.8076 1.47919 15.8076 2.42893 16.3934 3.01472L24.8787 11.5L16.3934 19.9853C15.8076 20.5711 15.8076 21.5208 16.3934 22.1066C16.9792 22.6924 17.9289 22.6924 18.5147 22.1066L28.0607 12.5607ZM2 13H27V10H2V13Z" />
		</svg>
	);
}
