import { PropsWithChildren, ReactElement } from "react";
import styles from "./Modal.module.scss";
import { nl2br } from "../utils/nl2br";
import { motion, AnimatePresence } from "framer-motion";
import { ANIMATION_FADE, ANIMATION_FADE_DOWN_SPRING } from "../utils/animations";

export type ModalPlainProps = {
	open: boolean;
	text?: string;
	onClose?: () => void;
	button?: ReactElement;
	buttons?: ReactElement[];
} & PropsWithChildren;

export default function ModalPlain({
	text,
	onClose,
	open,
	button,
	buttons,
	children,
}: ModalPlainProps) {
	return (
		<AnimatePresence mode="wait">
			{open && (
				<>
					<motion.div
						initial="exit"
						animate="enter"
						exit="exit"
						variants={ANIMATION_FADE}
						className={styles.mask}
					></motion.div>
					<motion.div
						initial="exit"
						animate="enter"
						exit="exit"
						variants={ANIMATION_FADE_DOWN_SPRING}
						className={styles.main}
					>
						<div className={styles.inner}>
							{!!onClose && (
								<button onClick={onClose} className={styles.close}>
									<Icon />
								</button>
							)}
							<div className={styles.content}>
								{!!text && <h1>{nl2br(text)}</h1>}
								{children}
								{button}
								{!!buttons?.length && (
									<div className={styles.buttons}>
										{buttons.map((button, index) => {
											return <div key={index}>{button}</div>;
										})}
									</div>
								)}
							</div>
						</div>
					</motion.div>
				</>
			)}
		</AnimatePresence>
	);
}

function Icon() {
	return (
		<svg viewBox="0 0 18.97 18.97">
			<path d="M11.61,9.49l7.07-7.07c.39-.39,.39-1.02,0-1.41l-.71-.71c-.39-.39-1.02-.39-1.41,0l-7.07,7.07L2.41,.29C2.02-.1,1.39-.1,1,.29L.29,1C-.1,1.39-.1,2.02,.29,2.41l7.07,7.07L.29,16.56c-.39,.39-.39,1.02,0,1.41l.71,.71c.39,.39,1.02,.39,1.41,0l7.07-7.07,7.07,7.07c.39,.39,1.02,.39,1.41,0l.71-.71c.39-.39,.39-1.02,0-1.41l-7.07-7.07Z" />
		</svg>
	);
}
