import Button from "../components/Button";
import styles from "./HyperCinema.module.scss";

export default function HyperCinema() {
	return (
		<div className={styles.main}>
			<svg
				viewBox="0 0 1170 94"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={styles.logo}>
				<path
					d="M112.292 92.6959H92.0679V52.6319H20.6439V92.6959H0.419922V1.30386H20.6439V35.6079H92.0679V1.30386H112.292V92.6959Z"
					fill="white"></path>
				<path
					d="M178.399 55.4479V92.6959H158.943V55.3199L123.103 1.30386H147.167L171.615 41.6239H166.751L191.327 1.30386H213.983L178.399 55.4479Z"
					fill="white"></path>
				<path
					d="M224.667 1.30386H332.315C339.91 1.30386 346.395 2.62652 351.771 5.27186C357.147 7.83186 361.243 11.3305 364.059 15.7679C366.96 20.2052 368.411 25.1545 368.411 30.6159C368.411 36.0772 366.96 41.0265 364.059 45.4639C361.243 49.9012 357.147 53.4425 351.771 56.0879C346.395 58.6479 339.91 59.9279 332.315 59.9279H236.187V43.0319H329.371C333.04 43.0319 336.07 42.5199 338.459 41.4959C340.848 40.3865 342.64 38.8932 343.835 37.0159C345.115 35.0532 345.755 32.8345 345.755 30.3599C345.755 27.9705 345.115 25.8799 343.835 24.0879C342.64 22.2105 340.848 20.7599 338.459 19.7359C336.07 18.6265 333.04 18.0719 329.371 18.0719H246.043V92.6959H224.667V1.30386Z"
					fill="white"></path>
				<path
					d="M381.795 1.30386H438.115V16.6639H401.123V36.7599H435.683V51.8639H401.123V77.3359H438.115V92.6959H381.795V1.30386Z"
					fill="white"></path>
				<path
					d="M494.127 43.1599L544.687 92.6959H518.959L471.727 44.4399V43.1599H494.127ZM535.087 29.5919C535.087 34.7119 533.764 39.2772 531.119 43.2879C528.474 47.2985 524.676 50.4559 519.727 52.7599C514.778 55.0639 508.89 56.2159 502.063 56.2159H463.791V42.3919H498.607C501.85 42.3919 504.623 41.8799 506.927 40.8559C509.316 39.7465 511.151 38.2105 512.431 36.2479C513.711 34.2852 514.351 32.0239 514.351 29.4639C514.351 26.8185 513.711 24.5572 512.431 22.6799C511.151 20.7172 509.316 19.1812 506.927 18.0719C504.623 16.9625 501.85 16.4079 498.607 16.4079H473.007V92.6959H453.295V1.30386H500.911C507.823 1.30386 513.839 2.49852 518.959 4.88786C524.079 7.27719 528.047 10.6052 530.863 14.8719C533.679 19.0532 535.087 23.9599 535.087 29.5919Z"
					fill="white"></path>
				<path
					d="M552.443 28.4399C554.918 22.8079 558.416 17.9012 562.939 13.7199C567.462 9.45319 572.838 6.12519 579.067 3.73586C585.296 1.34652 592.166 0.151855 599.675 0.151855H646.395C655.782 0.151855 663.846 1.64519 670.587 4.63186C677.414 7.61852 683.174 11.9705 687.867 17.6879L679.291 25.3679C676.219 20.7599 671.952 17.0905 666.491 14.3599C661.115 11.6292 654.544 10.2639 646.779 10.2639H600.315C594.342 10.2639 588.923 11.2025 584.059 13.0799C579.28 14.8719 575.142 17.4319 571.643 20.7599C568.144 24.0879 565.456 27.9705 563.579 32.4079C561.787 36.8452 560.891 41.7092 560.891 46.9999C560.891 52.2905 561.787 57.1545 563.579 61.5919C565.456 66.0292 568.144 69.9119 571.643 73.2399C575.142 76.5679 579.28 79.1705 584.059 81.0479C588.923 82.8399 594.342 83.7359 600.315 83.7359H646.779C654.459 83.7359 660.944 82.3705 666.235 79.6399C671.611 76.9092 675.835 73.1972 678.907 68.5039L687.995 76.1839C683.302 81.9012 677.542 86.2959 670.715 89.3679C663.974 92.3545 655.867 93.8479 646.395 93.8479H599.675C592.166 93.8479 585.296 92.6532 579.067 90.2639C572.838 87.8745 567.462 84.5892 562.939 80.4079C558.416 76.1412 554.918 71.1492 552.443 65.4319C549.968 59.7145 548.731 53.5279 548.731 46.8719C548.731 40.2159 549.968 34.0719 552.443 28.4399Z"
					fill="white"></path>
				<path
					d="M715.832 92.6959H704.056V1.30386H715.832V92.6959Z"
					fill="white"></path>
				<path
					d="M737.934 1.30386H746.382L809.614 82.7119H802.83V1.30386H813.454V92.6959H805.006L741.646 11.2879H748.43V92.6959H737.934V1.30386Z"
					fill="white"></path>
				<path
					d="M835.559 1.30386H888.039V10.3919H846.439V38.6799H885.607V47.6399H846.439V83.6079H888.039V92.6959H835.559V1.30386Z"
					fill="white"></path>
				<path
					d="M906.059 1.30386H931.275L980.427 82.9679H987.467L1036.23 1.30386H1061.19V92.6959H1049.42V11.0319H1042.63L993.739 92.6959H973.515L924.363 11.0319H917.451V92.6959H906.059V1.30386Z"
					fill="white"></path>
				<path
					d="M1097.64 57.7519H1147.44L1151.66 66.9679H1093.29L1097.64 57.7519ZM1121.07 11.2879H1123.88L1087.28 92.6959H1076.01L1118.12 1.30386H1127.08L1169.58 92.6959H1157.68L1121.07 11.2879Z"
					fill="white"></path>
			</svg>
			<div className={styles.button}>
				<Button href="https://hypercinema.ai">Find out more</Button>
			</div>
		</div>
	);
}
