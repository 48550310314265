import { Fragment } from "react";
import cx from "classnames";
import { useQuestions } from "../../contexts/QuestionContext";
import styles from "./Progress.module.scss";

export default function Progress() {
	const { step, questions, backOneStep } = useQuestions();

	return (
		<div className={styles.main}>
			<div className={styles.inner}>
				{questions.map((_, index) => {
					const isCurrent = step === index;
					const number = `${index + 1}/${questions.length}`;
					return (
						<div
							className={cx(styles.stepNumber, {
								[styles.current]: isCurrent,
							})}
							key={index}
						>
							<span>{number}</span>
						</div>
					);
				})}
			</div>
			<div className={styles.inner}>
				{questions.map((_, index) => {
					const isCurrent = step === index;
					const isComplete = step > index;
					return (
						<Fragment key={index}>
							<div
								className={cx(styles.stepBullet, {
									[styles.complete]: isComplete,
									[styles.current]: isCurrent,
								})}
							></div>
							<div className={styles.stepBar}></div>
						</Fragment>
					);
				})}
			</div>
			<button
				className={cx(styles.back, {
					[styles.active]: step > 0
				})}
				onClick={() => backOneStep()}
			>
				<Arrow />
				<span>Back</span>
			</button>
		</div>
	);
}

function Arrow() {
	return (
		<svg viewBox="0 0 17 9">
			<path d="M15.9629 5C16.239 5 16.4629 4.77614 16.4629 4.5C16.4629 4.22386 16.239 4 15.9629 4L15.9629 5ZM0.609336 4.14645C0.414074 4.34171 0.414074 4.65829 0.609336 4.85355L3.79132 8.03553C3.98658 8.2308 4.30316 8.2308 4.49842 8.03553C4.69369 7.84027 4.69369 7.52369 4.49842 7.32843L1.67 4.5L4.49842 1.67157C4.69369 1.47631 4.69369 1.15973 4.49842 0.964465C4.30316 0.769203 3.98658 0.769203 3.79132 0.964465L0.609336 4.14645ZM15.9629 4L0.96289 4L0.96289 5L15.9629 5L15.9629 4Z" />
		</svg>
	);
}
