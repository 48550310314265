import cx from "classnames";
import {
	FormEvent,
	HTMLProps,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import styles from "./TextInput.module.scss";
import { useDebounce } from "@uidotdev/usehooks";
import { useAppIdle } from "../../../contexts/AppContext";

export type ValidityPriority = Partial<Record<keyof ValidityState, number>>;
export type ValidityMessages = Partial<
	Record<keyof ValidityState, string | ((input: HTMLInputElement) => string)>
>;

export type TextInputProps = Pick<
	HTMLProps<HTMLInputElement>,
	"onInput" | "type" | "disabled" | "pattern"
> & {
	value: string;
	forceValid?: boolean;
	onValid?: (valid: boolean) => void;
	validityPriority?: ValidityPriority;
	validityMessages?: ValidityMessages;
};

const DEFAULT_PRIORITY: ValidityPriority = {
	tooShort: 1,
	tooLong: 2,
	patternMismatch: 3,
	valueMissing: 4,
	typeMismatch: 5,
};

const DEFAULT_MESSAGES: ValidityMessages = {
	valueMissing: "Please fill out this field.",
	typeMismatch: "Please enter a valid value.",
	tooShort: (input: HTMLInputElement) =>
		`Please lengthen this text to ${input.minLength} characters (you are currently using ${input.value.length} characters).`,
	tooLong: (input: HTMLInputElement) =>
		`Please shorten this text to ${input.maxLength} characters (you are currently using ${input.value.length} characters).`,
	patternMismatch: "Please enter a valid value.",
};

const getErrorPriority = (
	validity: ValidityState,
	validityPriority: ValidityPriority
): [string, number][] => {
	return (Object.entries(validityPriority) as [string, number][])
		.filter(([key]) => validity[key as keyof ValidityState])
		.sort((a, b) => a[1] - b[1]);
};

export default function TextInput({
	value,
	onInput,
	onValid,
	forceValid,
	validityPriority = DEFAULT_PRIORITY,
	validityMessages,
	...inputProps
}: TextInputProps) {
	const [hasError, setHasError] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [validationMessage, setValidationMessage] = useState<string>("");
	const input = useRef<HTMLInputElement>(null);
	const [dirty, setDirty] = useState(value !== "");
	const debouncedValue = useDebounce(value, 250);
	const isIdle = useAppIdle();

	const mergedMessages = useMemo(
		() => ({ ...DEFAULT_MESSAGES, ...validityMessages }),
		[validityMessages]
	);

	useEffect(() => {
		if (!isIdle) return;
		input.current?.blur();
	}, [isIdle]);

	useEffect(() => {
		setTimeout(() => {
			input.current?.focus();
		}, 250);
	}, []);

	useEffect(() => {
		if (!dirty) return;
		const valid = input.current?.checkValidity();
		setIsValid(!!valid);
		onValid?.(!!valid);
	}, [debouncedValue, dirty, onValid]);

	useEffect(() => {
		if (!forceValid) return;
		setDirty(true);
		setHasError(false);
		setValidationMessage("");
	}, [forceValid]);

	const onInvalid = useCallback(
		(event: FormEvent<HTMLInputElement>) => {
			const target = event.currentTarget;
			const { validity, validationMessage } = target;

			const prioritizedErrors = getErrorPriority(validity, validityPriority);
			let message = validationMessage;
			if (prioritizedErrors.length > 0 && mergedMessages) {
				const [errorType] = prioritizedErrors[0];
				const customMessage = mergedMessages[errorType as keyof ValidityState];
				message =
					typeof customMessage === "function"
						? customMessage(target)
						: customMessage || validationMessage;
			}

			setValidationMessage(message);
			setHasError(true);
		},
		// disabling as other dependencies are not needed
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[validityPriority, mergedMessages]
	);

	const onTextInput = useCallback(
		(event: FormEvent<HTMLInputElement>) => {
			setDirty(true);
			setValidationMessage("");
			setHasError(false);
			onInput?.(event);
		},
		[onInput]
	);

	return (
		<>
			<input
				ref={input}
				{...inputProps}
				autoComplete="off"
				autoCorrect="off"
				spellCheck={false}
				required
				value={value}
				onInvalid={onInvalid}
				onInput={onTextInput}
				className={cx(styles.input, {
					[styles.error]: hasError,
					[styles.valid]: isValid,
				})}
			/>
			<div className={cx(styles.message, { [styles.error]: hasError })}>
				{!!validationMessage && <p>{validationMessage}</p>}
			</div>
		</>
	);
}
