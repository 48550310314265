import { useLocalStorage } from "@uidotdev/usehooks";
import {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { BrowserRouter as Router } from "react-router-dom";
import appPackage from "../../package.json";
import KioskDebug from "../components/KioskDebug";
import Modal from "../components/Modal";
import { initialKioskCredential } from "../config";
import { AppMode, KioskCredential, KioskMode } from "../types";
import { callRemoteApi } from "../utils/callRemoteApi";
import logger, { setLoggerContext, toggleLoggerLevel } from "../utils/logger";
import useMatchMedia from "../utils/useMatchMedia";
import { ApiHandler } from "./ApiContext";
import { QuestionHandler } from "./QuestionContext";
import { SessionHandler } from "./SessionContext";

type AppInterface = {
	appMode: AppMode;
	kioskMode: KioskMode;
	kioskCredential: KioskCredential;
	setKioskCredential: (value: KioskCredential) => void;
	isIdle: boolean;
	setIsIdle: (value: boolean) => void;
	debugMode: boolean;
	toggleDebugMode: () => void;
	applyKioskMode: () => Promise<void>;
};

const AppContext = createContext<AppInterface>({} as AppInterface);

export default AppContext;

type AppProps = {
	kioskRender: (credential: KioskCredential) => ReactNode;
	publicRender: () => ReactNode;
};

export function AppHandler({ kioskRender, publicRender }: AppProps) {
	const [kioskCredential, setKioskCredential] =
		useLocalStorage<KioskCredential>("kioskCredential", initialKioskCredential);
	const appMode: AppMode = !!kioskCredential.kioskId ? "kiosk" : "public";
	const [isIdle, setIsIdle] = useState(false);
	// const isIdle = useIdle(inactivityTimeout);
	const [kioskMode, setKioskMode] = useState<KioskMode>("either");

	// 932px caters for iphone pro max which is the largest
	const isMobileLandscape = useMatchMedia(
		"(max-width: 932px) and (orientation: landscape)"
	);

	const [debugMode, setDebugMode] = useState(false);
	const toggleDebugMode = useCallback(() => {
		setDebugMode((value) => {
			const newValue = !value;
			toggleLoggerLevel(newValue);
			return newValue;
		});
	}, []);

	const applyKioskMode = useCallback(async () => {
		if (
			!kioskCredential.kioskId ||
			!kioskCredential.apiToken ||
			!kioskCredential.venueId
		)
			return;
		const result = await callRemoteApi(
			`/capture/mode?kioskId=${kioskCredential.kioskId}`,
			{},
			`${kioskCredential.venueId}:${kioskCredential.apiToken}`
		);

		setKioskMode(result.ok ? result.value.mode : "either");
	}, [
		kioskCredential.apiToken,
		kioskCredential.kioskId,
		kioskCredential.venueId,
	]);

	useEffect(() => {
		const kioskId = kioskCredential.kioskId;
		if (!kioskId) return;
		setLoggerContext({
			kioskId: kioskCredential.kioskId,
		});
		if (appMode === "kiosk")
			logger.info(
				`AppContext::start kioskId=${kioskId}, packageVersion=${appPackage.version}`
			);
	}, [appMode, kioskCredential.kioskId]);

	return (
		<AppContext.Provider
			value={{
				appMode,
				kioskCredential,
				setKioskCredential,
				isIdle,
				kioskMode,
				setIsIdle,
				debugMode,
				toggleDebugMode,
				applyKioskMode,
			}}
		>
			{appMode === "kiosk" && (
				<Router
					future={{
						v7_relativeSplatPath: true,
					}}
				>
					<SessionHandler>
						<ApiHandler>
							<QuestionHandler>
								<KioskDebug />
								{kioskRender(kioskCredential)}
							</QuestionHandler>
						</ApiHandler>
					</SessionHandler>
				</Router>
			)}

			{appMode === "public" && (
				<Router
					future={{
						v7_relativeSplatPath: true,
					}}
				>
					<SessionHandler>
						<ApiHandler>
							<QuestionHandler>
								{" "}
								{isMobileLandscape && (
									// @ts-ignore
									<Modal
										open={isMobileLandscape}
										text="Please Rotate Your Device"
									>
										<p>
											This app is best viewed in portrait mode.
											<br />
											Please rotate your device to continue using the app.
										</p>
									</Modal>
								)}
								{publicRender()}
							</QuestionHandler>
						</ApiHandler>
					</SessionHandler>
				</Router>
			)}
		</AppContext.Provider>
	);
}

export function useKioskCredential() {
	const { kioskCredential, setKioskCredential } = useContext(AppContext);
	return { kioskCredential, setKioskCredential };
}

export function useAppMode() {
	const { appMode } = useContext(AppContext);
	return appMode;
}

export function useAppIdle() {
	const { isIdle } = useContext(AppContext);
	return isIdle;
}

export function useSetAppIdle() {
	const { setIsIdle } = useContext(AppContext);
	return setIsIdle;
}

export function useKioskMode() {
	const { kioskMode } = useContext(AppContext);
	return kioskMode;
}

export function useDebugMode() {
	const { debugMode, toggleDebugMode } = useContext(AppContext);
	return { debugMode, toggleDebugMode };
}

export function useApplyKioskMode() {
	const { applyKioskMode } = useContext(AppContext);
	return applyKioskMode;
}
