import { JobsResponse, QuestionJob } from "../types";
import { callRemoteApi } from "./callRemoteApi";

export async function fetchCaptureJobs(
	passId: string,
	venueId: string,
	apiToken?: string
) {
	const result = await callRemoteApi<JobsResponse>(
		`/capture/jobs?passId=${passId}&venueId=${venueId}`
	);

	if (!result.ok) throw result.value;
	let { images, questions: partialQuestions } = result.value;

	let questions = (
		await Promise.all(
			partialQuestions.map((question) =>
				callRemoteApi<QuestionJob>(
					apiToken
						? `/job/${question.id}`
						: `/capture/job/${question.id}?venueId=${venueId}`,
					{},
					`${venueId}:${apiToken}`
				)
			)
		)
	)
		.map((result) => {
			if (!result.ok) throw result.value;
			return result.value;
		})
		.sort((a, b) =>
			(a.settings.order || 0) > (b.settings.order || 0) ? 1 : -1
		);

	// tweak for special testing passIds
	switch (passId) {
		// Images: Not done / Questions: Not done
		case "TEST000":
			images = images.map((image) => ({ ...image, status: "queued" }));
			questions = questions.map((image) => ({
				...image,
				status: "queued",
				value: undefined,
			}));
			break;
		// Images: Not done / Questions: Done
		case "TEST001":
			images = images.map((image) => ({ ...image, status: "queued" }));
			questions = questions.map((image) => ({
				...image,
				status: "ready",
				value: "-",
			}));
			break;
		// Images: Done / Questions: Not done
		case "TEST002":
			images = images.map((image) => ({ ...image, status: "ready" }));
			questions = questions.map((image) => ({
				...image,
				status: "queued",
				value: undefined,
			}));
			break;
		// Images: Done / Questions: Done
		case "TEST003":
			images = images.map((image) => ({ ...image, status: "ready" }));
			questions = questions.map((image) => ({
				...image,
				status: "ready",
				value: "-",
			}));
			break;
	}

	return { images, questions };
}
