import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useMemo, useState } from "react";
import zipCodeData from "../assets/data/zip_codes.json";
import Button from "../components/Button";
import Loader from "../components/Loader";
import Modal from "../components/Modal";
import Progress from "../components/questionnaire/Progress";
import Select from "../components/questionnaire/Select";
import Team from "../components/questionnaire/Team";
import Text from "../components/questionnaire/Text";
import ZipCode from "../components/questionnaire/ZipCode";
import { useApi } from "../contexts/ApiContext";
import { useQuestions } from "../contexts/QuestionContext";
import { useTransition } from "../contexts/TransitionContext";
import { useCall } from "../utils/useCall";
import styles from "./Questionnaire.module.scss";
import { useAppMode } from "../contexts/AppContext";

const COMPONENTS = {
	text: Text,
	email: Text,
	zipcode: ZipCode,
	select: Select,
	position: Select,
	team: Team,
};

const VARIANTS = {
	enter: { opacity: 1, transition: { duration: 0.5 } },
	exit: { opacity: 0, transition: { duration: 0.25 } },
};

function Questionnaire() {
	const { totalStep, step, questions } = useQuestions();
	return (
		<>
			<div className={styles.main}>
				<div className={styles.progress}>
					<Progress />
				</div>
				<div className={styles.container}>
					<AnimatePresence mode="wait">
						{step < totalStep &&
							questions.map((question, index) => {
								const Component = COMPONENTS[question.type];
								if (!Component) return null;
								return (
									index === step && (
										<motion.div
											key={index}
											initial="exit"
											animate="enter"
											exit="exit"
											variants={VARIANTS}
											style={{ height: "100%" }}
										>
											<Component {...question} index={index} />
										</motion.div>
									)
								);
							})}

						{step === totalStep && <SubmitStatus />}
					</AnimatePresence>
				</div>
			</div>
		</>
	);
}

function SubmitStatus() {
	const { questions, answers, resetStep } = useQuestions();
	const [modalOpen, setModalOpen] = useState(true);
	const { submitAnswers } = useApi();
	const transition = useTransition();
	const appMode = useAppMode();

	const prepareAndSubmit = useCallback(async () => {
		// determine zipcode at submission
		// this is to prevent weird "back" states that would
		// cause user confusion when overriding invalid zipcode
		// or outside US checkbox is ticked
		const getZipCodeValue = (answer, { outsideValue, outsideValueAlias }) => {
			if (answer === outsideValueAlias) return outsideValue;
			const isValidZip = zipCodeData.zip_codes.includes(answer);
			return isValidZip ? answer : outsideValue;
		};

		if (questions.length !== Object.values(answers).length)
			throw new Error(
				`number of answers does not match with number of questions`
			);

		const records = Object.keys(answers).map((key) => {
			const answerIndex = parseInt(key, 10);
			const question = questions[answerIndex];

			const isZipCode = question.type === "zipcode";
			const answer = isZipCode
				? getZipCodeValue(answers[key], questions[answerIndex])
				: answers[key];

			if (!answer) throw new Error(`answer at ${answerIndex} is empty`);

			return answer;
		});

		await submitAnswers(records);
		setTimeout(() => {
			const nextRoute =
				appMode === "kiosk" ? "../confirmation" : "../departure";
			transition(nextRoute);
		}, 2000);
		setTimeout(() => {
			setModalOpen(false);
		}, 3000);
	}, [answers, appMode, questions, submitAnswers, transition]);

	const onTryAgain = useCallback(() => {
		resetStep(false);
	}, [resetStep]);

	const { error } = useCall(prepareAndSubmit);

	const modalProps = useMemo(() => {
		if (error)
			return {
				text: "There is an issue submitting your answers.\nPlease try again.",
				button: <Button onClick={onTryAgain}>Try again</Button>,
			};

		return {
			text: "Please wait...",
			button: <Loader size="lg" />,
		};
	}, [error, onTryAgain]);

	return <Modal open={modalOpen} {...modalProps} />;
}

export default Questionnaire;
