import { useCallback, useEffect, useMemo } from "react";
import welcome from "../assets/welcome.png";
import Loader from "../components/Loader";
import Modal from "../components/Modal";
import PassIDDebug from "../components/PassIDDebug";
import styles from "./Welcome.module.scss";
import { useSearchParams } from "react-router-dom";
import { useTransition } from "../contexts/TransitionContext";
import { findNextRoute } from "../utils/findNextRoute";
import { useFullScreen } from "../utils/useFullScreen";
import { useLogIn } from "../utils/useLogIn";
import { useLogOut } from "../utils/useLogOut";
import logger from "../utils/logger";

function Welcome() {
	const transition = useTransition();
	const [searchParams, setSearchParams] = useSearchParams();
	const onPanelClick = useFullScreen();
	const scannedPassId = searchParams.get("scannedPassId");
	const {
		data: captureJobs,
		pending,
		error,
		resetState,
	} = useLogIn(scannedPassId);
	useLogOut(scannedPassId);

	const onTryAgain = useCallback(() => {
		setSearchParams((prev) => {
			const { scannedPassId, ...next } = prev;
			return next;
		});
		resetState();
	}, [resetState, setSearchParams]);

	const modalOpen = useMemo(() => {
		if (!scannedPassId) return false;
		return pending || !!error;
	}, [error, scannedPassId, pending]);

	useEffect(() => {
		if (!error) return;
		const timeoutId = setTimeout(onTryAgain, 2000);
		return () => clearTimeout(timeoutId);
	}, [error, onTryAgain]);

	const modalProps = useMemo(() => {
		if (error)
			return {
				text: "There is an issue reading your pass.\nPlease try again.",
				button: null,
			};

		return {
			text: "Please wait...",
			button: <Loader size="lg" passIdState="accepted" />,
		};
	}, [error]);

	// redirect user to the next page depending on their `captureJobs` data
	useEffect(() => {
		if (!captureJobs) return;
		const nextRoute = findNextRoute(["kiosk"], captureJobs);
		transition(nextRoute);
		logger.info(`Welcome::logIn redirect user, nextRoute=${nextRoute}`);
	}, [captureJobs, transition]);

	return (
		<>
			<div className={styles.main} onClick={onPanelClick}>
				<img src={welcome} alt="" />
			</div>
			<PassIDDebug />
			<Modal open={modalOpen} {...modalProps} />
		</>
	);
}

export default Welcome;
