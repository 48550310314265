import { useEffect, useRef, useState } from "react";

export function useCall<T = void>(onSubmit: () => Promise<T>) {
	const hasCalled = useRef(false);
	const [pending, setPending] = useState(false);
	const [error, setError] = useState<unknown>();
	const [result, setResult] = useState<T>();

	useEffect(() => {
		if (hasCalled.current) return;
		hasCalled.current = true;
		setPending(true);
		onSubmit()
			.then((result) => {
				setResult(result);
				setPending(false);
			})
			.catch((error) => {
				setError(error);
				setPending(false);
			});
	}, [onSubmit]);

	return { pending, error, result };
}
