import { useEffect, useState } from "react";
import { useKioskCredential } from "../contexts/AppContext";
import appPackage from "../../package.json";

export default function KioskDebug() {
	const [visible, setVisible] = useState(false);
	const { kioskCredential } = useKioskCredential();

	useEffect(() => {
		if (!visible) return;
		setTimeout(() => setVisible(false), 10000);
	}, [visible]);

	return (
		<div
			onClick={() => setVisible((visible) => !visible)}
			style={{
				transition: "opacity 0.2s",
				opacity: visible ? 1 : 0,
				position: "absolute",
				bottom: "0",
				left: "0",
				padding: "0.75em",
				color: "red",
				zIndex: 1000,
				border: "1px solid red",
			}}
		>
			<pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}>
				{JSON.stringify({
					version: appPackage.version,
					...kioskCredential,
					apiToken: `${kioskCredential.apiToken.slice(0, 5)}***`,
				})}
			</pre>
		</div>
	);
}
