import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";

export function useManualPassIdInput() {
	const [userInputPassId, setUserInputPassId] = useState<string>();
	const [touchPassId, setTouchPassId] = useState<string>();
	const debouncedTouchPassId = useDebounce(touchPassId, 3000);

	const cycleThroughPassId = useCallback(() => {
		setTouchPassId((value) => {
			const list = ["TEST000", "TEST001", "TEST002", "TEST003"];
			const index = list.indexOf(value ?? "");
			let nextValue =
				index === undefined || index === list.length - 1
					? list[0]
					: list[index + 1];
			setUserInputPassId(nextValue);
			return nextValue;
		});
	}, []);

	// Keyboard Mode: we listen to a sequence of key strokes
	// start with `[` and end with `]``
	useEffect(() => {
		let sequences: string[] = [];
		const keyDownHandler = async (event: KeyboardEvent) => {
			const { key } = event;
			switch (key) {
				case "[":
					sequences = [];
					break;
				case "]":
					const userInput = sequences.join("").toUpperCase();
					setUserInputPassId(userInput);
					setTouchPassId(userInput);
					return;
				default:
					sequences.push(key);
					return;
			}
		};

		window.addEventListener("keydown", keyDownHandler);
		return () => window.removeEventListener("keydown", keyDownHandler);
	}, []);

	return {
		userInputPassId,
		debouncedTouchPassId,
		cycleThroughPassId,
	};
}
