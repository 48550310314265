import cx from "classnames";
import { MouseEventHandler, ReactNode } from "react";
import Head from "./Head";
import styles from "./TextGrid.module.scss";

export type TextGridProps = {
	label: string;
	value: string;
	options: { label: string; value: string }[] | string[];
	onClick?: MouseEventHandler<HTMLButtonElement>;
	controls?: ReactNode;
	isInModal?: boolean;
};

export default function TextGrid({
	options,
	label,
	value,
	onClick,
	controls,
	isInModal,
	...inputProps
}: TextGridProps) {
	return (
		<div className={cx(styles.main, { [styles.isInModal]: isInModal })}>
			<input {...inputProps} type="hidden" value={value} />
			<Head className={styles.head} label={label} />
			<div className={cx(styles.inner)}>
				<div
					className={cx(styles.options, {
						[styles.alt]: options.length > 20,
					})}
				>
					{options.map((option, index) => {
						const itemLabel =
							typeof option === "string" ? option : option.label;
						const itemValue =
							typeof option === "string" ? option : option.value;

						const isSelected = !!value && value === itemValue;
						const isDismissed = !!value && value !== itemValue;

						return (
							<button
								className={cx(styles.option, {
									[styles.selected]: isSelected,
									[styles.dismissed]: isDismissed,
								})}
								key={index}
								value={itemValue}
								onClick={onClick}
							>
								{itemLabel}
							</button>
						);
					})}
				</div>
			</div>
			<div className={styles.mask}></div>
			{!!controls && <div className={styles.controls}>{controls}</div>}
		</div>
	);
}
