import { Fragment } from "react";

export const nl2br = (text) => {
	const lines = text.split("\n");
	return lines.map((line, index) => (
		<Fragment key={index}>
			{line}
			{index < lines.length - 1 && <br />}
		</Fragment>
	));
};
