import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./Modal.module.scss";
import { nl2br } from "../utils/nl2br";

import { ANIMATION_FADE, ANIMATION_FADE_DOWN_SPRING } from "../utils/animations";

function Icon() {
	return (
		<svg viewBox="0 0 18.97 18.97">
			<path d="M11.61,9.49l7.07-7.07c.39-.39,.39-1.02,0-1.41l-.71-.71c-.39-.39-1.02-.39-1.41,0l-7.07,7.07L2.41,.29C2.02-.1,1.39-.1,1,.29L.29,1C-.1,1.39-.1,2.02,.29,2.41l7.07,7.07L.29,16.56c-.39,.39-.39,1.02,0,1.41l.71,.71c.39,.39,1.02,.39,1.41,0l7.07-7.07,7.07,7.07c.39,.39,1.02,.39,1.41,0l.71-.71c.39-.39,.39-1.02,0-1.41l-7.07-7.07Z" />
		</svg>
	);
}

function Countdown({ duration = 9, onComplete }) {
	const [countdown, setCountdown] = useState(duration);
	const [timer, setTimer] = useState(true);

	useEffect(() => {
		if (!timer) {
			return;
		}
		if (countdown === 0) {
			onComplete();
			setTimer(false);
			return;
		}
		const timeout = setTimeout(() => {
			setCountdown((prevCountdown) => prevCountdown - 1);
		}, 1000);
		return () => clearTimeout(timeout);
	}, [timer, countdown, onComplete]);

	return <h2 className={styles.timer}>{countdown}</h2>;
}

function Modal({
	open,
	close,
	text,
	children,
	button,
	buttons,
	timer,
	onTimerComplete
}) {
	const handleCountdownComplete = () => {
		if (onTimerComplete) {
			onTimerComplete();
		} else {
			setTimeout(() => {
				// Default behavior (Reset App ??)
			}, 1000);
		}
	};

	return (
		<AnimatePresence mode="wait">
			{open && (
				<>
					<motion.div
						initial="exit"
						animate="enter"
						exit="exit"
						variants={ANIMATION_FADE}
						className={styles.mask}></motion.div>
					<motion.div
						initial="exit"
						animate="enter"
						exit="exit"
						variants={ANIMATION_FADE_DOWN_SPRING}
						className={styles.main}>
						<div className={styles.inner}>
							{close && (
								<button onClick={close} className={styles.close}>
									<Icon />
								</button>
							)}
							{!!timer && (
								<Countdown
									duration={timer}
									onComplete={handleCountdownComplete}
								/>
							)}
							<div className={styles.content}>
								<h1>{nl2br(text)}</h1>
								{children}
								{button}
								{!!buttons?.length && (
									<div className={styles.buttons}>
										{buttons.map((button, index) => {
											return <div key={index}>{button}</div>;
										})}
									</div>
								)}
							</div>
						</div>
					</motion.div>
				</>
			)}
		</AnimatePresence>
	);
}

export default Modal;
