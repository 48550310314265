import { useState } from "react";
import Button from "../components/Button";
import Modal from "../components/Modal";
import Checkbox from "../components/Checkbox";
import styles from "./Age.module.scss";

function Age() {
	const [modalOpen, setModalOpen] = useState(false);
	const [state, setState] = useState("");

	return (
		<>
			<div className={styles.main}>
				<div className={styles.column}>
					<h2>I am under 18</h2>
					<p>
						<label htmlFor="underAge">
							I am under 18 and my parent or guardian will accept for me
						</label>
						<Checkbox
							id="underAge"
							className={styles.checkbox}
							checked={state === "underAge"}
							onChange={() => setState("underAge")}
						/>
					</p>
					<Button href="../start" disabled={state !== "underAge"}>
						Let's Go
					</Button>
				</div>
				<div className={styles.column}>
					<h2>I am 18 or over</h2>
					<p>
						<label htmlFor="overAge">
							I am 18 or over and accept the{" "}
							<button onClick={() => setModalOpen(true)}>
								terms & conditions
							</button>
						</label>
						<Checkbox
							id="overAge"
							className={styles.checkbox}
							checked={state === "overAge"}
							onChange={() => setState("overAge")}
						/>
					</p>
					<Button href="../start" disabled={state !== "overAge"}>
						Let's Go
					</Button>
				</div>
			</div>
			<Modal
				open={modalOpen}
				close={() => setModalOpen(false)}
				text="Terms and Conditions">
				<div className={styles.terms}>
					<h3>
						THIS TICKET IS A REVOCABLE LICENSE. USER ACCEPTS RISK OF INJURY.
					</h3>
					<p>
						By entering the College Football Hall of Fame (“CFHOF”), the ticket
						purchaser/holder (“Holder”) certifies that he or she has had the
						opportunity to review and accept each of the terms and conditions of
						this Agreement for him or herself and as a parent or guardian on
						behalf of any accompanying minor Holder. If you do not agree to
						these terms, return the ticket immediately for a refund. This ticket
						is a non-refundable, limited license for access to the CFHOF, which
						may be revoked by Atlanta Hall Management, Inc. (“AHM”) at any time
						with or without cause at the discretion of AHM. Tickets are valid
						only for the date indicated on the face of the ticket and valid for
						use only by Holder. By use of this ticket, Holder agrees to
						voluntarily assume all risk of incident with respect to the CFHOF,
						including the risk of lost, stolen or damaged property or personal
						injury. AHM and any such third parties it may authorize, are
						irrevocably granted permission to use Holder’s image, likeness,
						name, voice, comments or other proprietary or public rights of
						Holder and that of any minor accompanying Holder, for all purposes,
						in any media without compensation. No outside food or beverage is
						allowed in the CFHOF. All persons and bags are subject to search and
						security checks. No dangerous objects including, but without
						limitation, guns, knives, weapons, fireworks, flammable substances,
						lighters or matches are permitted inside the CFHOF. The CFHOF is a
						non-smoking, tobacco free facility. You are welcome to take
						photographs and video for your personal use during your visit,
						however, such photographs and video cannot be used for broadcast,
						publication, or any commercial purpose without the prior written
						permission of AHM. AHM may refuse admission to, or eject Holder if
						Holder is deemed disruptive or disorderly (including but not limited
						to, being under the influence of alcohol, drugs or other substance)
						or fails to comply with CFHOF rules, instructions from AHM
						personnel, these terms, or any security measures. No refunds,
						exchanges or re-admission are permitted once the Holder has entered
						the CFHOF.
					</p>
				</div>
			</Modal>
		</>
	);
}

export default Age;
