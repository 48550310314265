import styles from "./Layout.module.scss";
import cx from "classnames";
import { useState, useCallback } from "react";
import { useAppMode } from "../../../contexts/AppContext";
import useMatchMedia from "../../../utils/useMatchMedia";
import Modal from "../../ModalPlain";
import TextGrid from "./TextGrid";
import TextList from "./TextList";

function Layout({
	textInput = false,
	children,
	label,
	controls,
	examples = [],
	examplesLabel = "A few examples",
	onExampleSelect = (value) => {},
}) {
	const appMode = useAppMode();
	const [modalOpen, setModalOpen] = useState(false);
	const isLargeScreen = useMatchMedia("(min-width: 1280px)");

	const [selectedExample, setSelectedExample] = useState("");
	const onExampleClick = useCallback(
		(event) => {
			const value = event.currentTarget.value;
			setSelectedExample(value);
			onExampleSelect?.(value);

			setTimeout(() => {
				setModalOpen(false);
			}, 200);

			setTimeout(() => {
				setSelectedExample("");
			}, 500);
		},
		[onExampleSelect]
	);

	return (
		<div className={styles.main}>
			<div
				className={cx(styles.inner, {
					[styles.kiosk]: appMode === "kiosk" && textInput,
				})}
			>
				{!!examples?.length && (
					<button
						className={styles.examples}
						onClick={() => setModalOpen(true)}
					>
						See examples
					</button>
				)}
				<label className={styles.label}>{label}</label>
				{children}
			</div>
			<div className={styles.controls}>{controls}</div>

			{!!examples?.length && (
				<Modal open={modalOpen} onClose={() => setModalOpen(false)}>
					{isLargeScreen && (
						<TextGrid
							options={examples}
							label={examplesLabel}
							value={selectedExample}
							isInModal={true}
							onClick={onExampleClick}
						/>
					)}

					{!isLargeScreen && (
						<TextList
							options={examples}
							label={examplesLabel}
							value={selectedExample}
							isInModal={true}
							onClick={onExampleClick}
						/>
					)}
				</Modal>
			)}
		</div>
	);
}

export default Layout;
