import { Result } from "../types";
import { apiUrl } from "../config";
import logger from "./logger";

export const callRemoteApi = async <T = any>(
	path: string,
	options: RequestInit = {},
	bearerToken: string | null = null
) => {
	try {
		logger.debug(`callRemoteApi::request ${options?.method || "GET"} ${path}`);
		const response = await fetch(`${apiUrl}${path}`, {
			headers: bearerToken
				? {
						"Authorization": `Bearer ${bearerToken}`,
						"Content-Type": "application/json",
					}
				: { "Content-Type": "application/json" },
			...options,
		});
		logger.debug(
			`callRemoteApi::response ${options?.method || "GET"} ${path} ${response.status}`
		);

		if (response.status >= 400)
			throw new Error("callRemoteApi::error", {
				cause: {
					status: response.status,
					message: (await response.text()) ?? response.statusText,
				},
			});

		const body = await response.text();
		const json = JSON.parse(body);
		return { ok: true, value: json } as Result<T>;
	} catch (e) {
		return {
			ok: false,
			value:
				e instanceof Error
					? e
					: new Error(`callRemoteApi::error`, { cause: e }),
		} as Result<never>;
	}
};
