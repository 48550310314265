import { useEffect, useMemo } from "react";
import useWebSocket from "react-use-websocket";
import { CFHOF_KIOSK_ROUTE, websocketUrl } from "../config";
import { WebsocketEvent } from "../types";
import { useSession } from "../contexts/SessionContext";
import { useTransition } from "../contexts/TransitionContext";
import { useNavigate } from "react-router-dom";
import logger from "../utils/logger";

type PassScanListenerProps = {
	token: string;
	venueId: number;
	kioskId: number;
};

export function PassScanListener({
	token,
	venueId,
	kioskId,
}: PassScanListenerProps) {
	const transition = useTransition();
	const navigate = useNavigate();
	const { userCredential, sessionTime } = useSession();
	const { lastJsonMessage } = useWebSocket<WebsocketEvent>(
		`${websocketUrl}/_ws`,
		{
			queryParams: { apiToken: `${venueId}:${token}` },
			shouldReconnect: () => true,
			reconnectInterval: (attemptNumber) =>
				Math.min(attemptNumber * 1000, 10000),
			heartbeat: true,
			retryOnError: true,
			onError: (error) =>
				logger.error("PassScanListener::websocket error", error),
			onReconnectStop: (numAttempts) =>
				logger.info(
					`PassScanListener::websocket stopped reconnecting after ${numAttempts} attempts`
				),
			filter: (websocketMessage) => {
				const { data } = websocketMessage;
				const lastJsonMessage = JSON.parse(data);
				const valid =
					"channel" in lastJsonMessage &&
					lastJsonMessage?.channel === "pass-scans" &&
					lastJsonMessage?.venueId === venueId &&
					lastJsonMessage?.message?.nodeType === "capture";

				if (valid && lastJsonMessage?.message?.nodeId === kioskId)
					logger.info(
						`PassScanListener::websocket receive message, nodeId=${lastJsonMessage?.message?.nodeId}, passId=${lastJsonMessage?.message?.passId}`
					);

				return valid;
			},
		}
	);

	const lastEvent = useMemo(
		() =>
			lastJsonMessage
				? { ...lastJsonMessage, timestamp: Date.now() }
				: undefined,
		[lastJsonMessage]
	);

	useEffect(() => {
		if (!lastEvent) return;
		const { message } = lastEvent;

		// We ignore either cases below..	.
		// 1. User scan the pass again on the same kiosk, passIdMatched = true; kioskIdMatched = true
		// 2. Different user scan on a different kiosk, passIdMatched = false; kioskIdMatched = false
		const passIdMatched = message.passId === userCredential?.passId;
		const kioskIdMatched = message.nodeId === kioskId;
		if (passIdMatched === kioskIdMatched) {
			logger.debug(
				`PassScanListener::websocket ignore message, passId=${message.passId}, kioskId=${message.nodeId}, passIdMatched=${passIdMatched}, kioskIdMatched=${kioskIdMatched}`
			);
			return;
		}

		// 3. Same Pass Id but at different kiosk, log user out
		if (passIdMatched && !kioskIdMatched) {
			logger.debug(
				`PassScanListener::websocket same Pass ID but at different Kiosk, log user out`
			);
			return transition(CFHOF_KIOSK_ROUTE);
		}

		if (lastEvent.timestamp >= sessionTime) {
			logger.info(
				`PassScanListener::websocket redirect to log in, passId=${message.passId}`
			);
			navigate(`${CFHOF_KIOSK_ROUTE}?scannedPassId=${message.passId}`);
		}
	}, [
		kioskId,
		lastEvent,
		navigate,
		sessionTime,
		transition,
		userCredential?.passId,
	]);

	return null;
}
