import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import {
	MouseEventHandler,
	ReactNode,
	useCallback,
	useMemo,
	useState,
} from "react";
import useMatchMedia from "../../utils/useMatchMedia";
import Controls, { ControlsNavigationHandler } from "./Controls";
import styles from "./Team.module.scss";
import Layout from "./shared/Layout";
import TeamGrid, { TeamGridProps } from "./shared/TeamGrid";
import TextGrid from "./shared/TextGrid";
import TextList from "./shared/TextList";
import { useQuestions } from "../../contexts/QuestionContext";

type TeamData = {
	School: string;
	State: string;
	Nickname?: string;
	RivalSchool?: string;
	LogoThumb?: string;
};

type TeamProps = {
	index: number;
	label: string;
	stateLabel: string;
	teamLabel: string;
	placeholder: string;
	teamData: TeamData[];
};

const MOTION_VARIANTS = {
	enter: { opacity: 1, transition: { duration: 0.5 } },
	exit: { opacity: 0, transition: { duration: 0.25 } },
};

const stageStates = ["prompt", "select-state", "select-team"] as const;
type StageState = (typeof stageStates)[number];

export default function Team({
	index,
	label,
	stateLabel,
	teamData,
	teamLabel,
	placeholder,
	...inputProps
}: TeamProps) {
	const { answers, saveAnswer } = useQuestions();
	const isLargeScreen = useMatchMedia("(min-width: 1280px)");
	const [team, setTeam] = useState<string>(answers[index] || "");
	const [teamState, setTeamState] = useState<string>("");
	const [stage, setStage] = useState<StageState>("prompt");
	const stateTeamOptions = useMemo(
		() => getTeamStateOptions(teamData),
		[teamData]
	);
	const teamOptions = useMemo(
		() => getTeamOptions(teamState, teamData),
		[teamData, teamState]
	);
	const teamListOptions = useMemo(() => {
		if (isLargeScreen) return;
		return getTeamGroupedByState(teamData);
	}, [isLargeScreen, teamData]);

	const stateOfSelectedTeam = useMemo(() => {
		return teamData.find((v) => v.School === team)?.State;
	}, [teamData, team]);

	const backNavigation: ControlsNavigationHandler = (back) => {
		const currentStage = stageStates.indexOf(stage);
		if (currentStage === 0) return back(); // go back to previous question

		// go back to previous stage
		const newStageState = stageStates[currentStage - 1];
		setStage(newStageState);
		setTeam("");
	};

	const nextNavigation: ControlsNavigationHandler = (next) => {
		const currentStage = stageStates.indexOf(stage);
		const isFirstStageWithTeamSet = currentStage === 0 && team;
		const isLastStage = currentStage === stageStates.length - 1;

		// go to next question
		if (isLastStage || isFirstStageWithTeamSet) {
			saveAnswer(index, team);
			return next();
		}

		// go to next stage
		const newStageState = stageStates[currentStage + 1];
		setStage(newStageState);
	};

	const controls = (
		<Controls
			canProceed={!!team}
			onBack={(back) => backNavigation(back)}
			onNext={(next) => nextNavigation(next)}
		/>
	);

	const onPromptClick = useCallback<
		MouseEventHandler<HTMLButtonElement>
	>(() => {
		setStage("select-state");
		setTeamState(stateOfSelectedTeam || "");
	}, [stateOfSelectedTeam]);

	const onStateClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
		(event) => {
			const clickedState = event.currentTarget.value;
			if (clickedState !== stateOfSelectedTeam) setTeam(""); // reset team if state changes

			setTeamState(clickedState);
			setStage("select-team");
		},
		[stateOfSelectedTeam]
	);

	const onTeamClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
		(event) => {
			setTeam(event.currentTarget.value);
		},
		[]
	);

	return (
		<>
			{!isLargeScreen && teamListOptions && (
				<TextList
					{...inputProps}
					label={label}
					controls={controls}
					options={teamListOptions}
					value={team}
					onClick={(event) => setTeam(event.currentTarget.value)}
				/>
			)}

			{isLargeScreen && (
				<AnimatePresence mode="wait">
					<input {...inputProps} type="hidden" value={team} />
					{stage === "prompt" && (
						<MotionWrapper key="prompt">
							<Layout
								label={label}
								controls={stage === "prompt" ? controls : null}
							>
								<div className={styles.options}>
									<button
										className={cx(styles.option, { [styles.dismissed]: !team })}
										onClick={onPromptClick}
									>
										<span>{team || placeholder}</span>
										<EditIcon />
									</button>
								</div>
							</Layout>
						</MotionWrapper>
					)}
					{stage === "select-state" && (
						<MotionWrapper key="select-state">
							<TextGrid
								options={stateTeamOptions}
								label={stateLabel}
								value={teamState}
								onClick={onStateClick}
								controls={controls}
							/>
						</MotionWrapper>
					)}
					{stage === "select-team" && (
						<MotionWrapper key="select-team">
							<TeamGrid
								options={teamOptions}
								label={teamLabel}
								value={team}
								onClick={onTeamClick}
								controls={controls}
							/>
						</MotionWrapper>
					)}
				</AnimatePresence>
			)}
		</>
	);
}

const MotionWrapper = ({ children }: { children: ReactNode }) => (
	<motion.div
		initial="exit"
		animate="enter"
		exit="exit"
		variants={MOTION_VARIANTS}
		style={{ height: "100%" }}
	>
		{children}
	</motion.div>
);

function EditIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
			<path d="M18.375 2.625a1 1 0 0 1 3 3l-9.013 9.014a2 2 0 0 1-.853.505l-2.873.84a.5.5 0 0 1-.62-.62l.84-2.873a2 2 0 0 1 .506-.852z" />
		</svg>
	);
}

function getTeamStateOptions(teamData: TeamData[]) {
	return teamData
		.reduce<string[]>((states, team) => {
			return states.includes(team.State) ? states : states.concat(team.State);
		}, [])
		.sort();
}

function getTeamOptions(
	state: string,
	teamData: TeamData[]
): TeamGridProps["options"] {
	if (state === "") return [];
	return teamData
		.filter((team) => team.State === state)
		.map((team) => {
			const name = team.School;
			const nickname = team.Nickname || "";
			const logo = team.LogoThumb || "";
			return { name, nickname, value: name, logo };
		})
		.sort((a, b) => a.name.localeCompare(b.name));
}

function getTeamGroupedByState(teamData: TeamData[]) {
	const teamsByState = teamData.reduce<
		Record<string, { label: string; value: string }[]>
	>((options, team) => {
		const { State, School } = team;

		if (!options[State]) options[State] = [];
		options[State].push({ label: School, value: School });
		return options;
	}, {});

	return Object.keys(teamsByState)
		.sort((a, b) => a.localeCompare(b))
		.map((state) => {
			const teams = teamsByState[state];

			if (!teams.length) {
				console.warn(`${state} doesn't have any teams`);
				return null;
			}

			const sortedTeams = teams.sort((a, b) => a.label.localeCompare(b.label));
			return {
				title: state,
				items: sortedTeams,
			};
		})
		.filter((state) => state !== null);
}
