import {
	createContext,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useSession } from "./SessionContext";

type QuestionInterface = {
	questions: Record<string, any>[];
	answers: Record<string, string>;
	step: number;
	totalStep: number;
	backOneStep: () => void;
	nextOneStep: () => void;
	resetStep: () => void;
	saveAnswer: (index: number, answer: string) => void;
};

const QuestionContext = createContext<QuestionInterface>(
	{} as QuestionInterface
);

export default QuestionContext;

type QuestionProps = {} & PropsWithChildren;

export function QuestionHandler({ children }: QuestionProps) {
	const { captureJobs, userCredential } = useSession();
	const questions = useMemo(() => {
		const questions = captureJobs?.questions
			? captureJobs?.questions.map(({ settings }) => settings)
			: [];

		if (!questions.length) return questions;

		return questions;
	}, [captureJobs]);
	const [answers, setAnswers] = useState<Record<string, string>>({});
	const allAnswered = useMemo(
		() => captureJobs?.questions.every((question) => !!question.value),
		[captureJobs]
	);
	const [step, setStep] = useState<number>(0);

	const backOneStep = useCallback(() => {
		setStep((step) => Math.max(0, step - 1));
	}, []);
	const nextOneStep = useCallback(() => {
		setStep((step) => Math.min(questions.length, step + 1));
	}, [questions.length]);

	const saveAnswer = useCallback(
		(index: number, answer: string) => {
			setAnswers((answers) => {
				let nextAnswers: Record<string, string> = {
					...answers,
					[index]: answer,
				};

				// add a hack to pre-populate the rival school
				// this under the assumption that the team questions are next to each other
				const currentQuestion = questions[index],
					nextQuestion = questions[index + 1];

				if (currentQuestion.type === "team" && nextQuestion?.type === "team") {
					const { teamData } = currentQuestion as unknown as {
						teamData: { School: string; RivalSchool: string }[];
					};

					const schoolIndex = teamData.findIndex(
						({ School }) => answer === School
					);
					const school = teamData[schoolIndex];

					if (school) {
						nextAnswers = {
							...nextAnswers,
							[index + 1]: school.RivalSchool,
						};
						nextQuestion.teamData = [...teamData].toSpliced(schoolIndex, 1);
					}
				}

				return nextAnswers;
			});
		},
		[questions]
	);

	const resetStep = useCallback((hard = true) => {
		setStep(0);
		if (hard) setAnswers({});
	}, []);

	// auto reset step when user is being logged out
	useEffect(() => {
		if (userCredential?.passId) return;
		resetStep();
	}, [resetStep, userCredential]);

	useEffect(() => {
		if (!allAnswered || !captureJobs?.questions.length) return;
		const answers = captureJobs?.questions.reduce<Record<string, string>>(
			(answers, { value }, index) => {
				answers[index] = value!;
				return answers;
			},
			{}
		);
		setAnswers(answers);
		setStep(questions.length);
	}, [allAnswered, captureJobs?.questions, questions.length]);

	return (
		<QuestionContext.Provider
			value={{
				questions,
				step,
				totalStep: questions.length,
				answers,
				backOneStep,
				nextOneStep,
				resetStep,
				saveAnswer,
			}}
		>
			{children}
		</QuestionContext.Provider>
	);
}

export function useQuestions() {
	return useContext(QuestionContext);
}
