import cx from "classnames";
import { MouseEventHandler, ReactNode, useEffect, useRef } from "react";
import styles from "./TextList.module.scss";

type OptionsList = { label: string; value: string }[] | string[];
type NestedOptionsList = { title: string; items: OptionsList }[];

export type TextListProps = {
	label: string;
	value: string;
	options: OptionsList | NestedOptionsList;
	onClick?: MouseEventHandler<HTMLButtonElement>;
	controls?: ReactNode;
	isInModal?: boolean;
};

export default function List({
	options,
	label,
	value,
	onClick,
	controls,
	isInModal,
	...inputProps
}: TextListProps) {
	const hasTitle =
		typeof options?.[0] !== "string" && Object.hasOwn(options?.[0], "title");

	const normalisedOptions = hasTitle
		? (options as NestedOptionsList)
		: [{ title: undefined, items: options as OptionsList }];

	const buttonRefs = useRef<{ [key: string]: HTMLButtonElement | null }>({});
	const containerRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (value && buttonRefs.current) {
			const selectedButton = buttonRefs.current[value];
			if (selectedButton && containerRef.current) {
				selectedButton.scrollIntoView({ behavior: "smooth", block: "center" });
			}
		}
	}, [value]);

	return (
		<div className={cx(styles.main, { [styles.isInModal]: isInModal })}>
			<input {...inputProps} type="hidden" value={value} />
			<div className={styles.head}>
				<span className={styles.label}>{label}</span>
			</div>
			<div className={styles.list} ref={containerRef}>
				{normalisedOptions.map(({ title, items }, index) => (
					<div className={cx({ [styles.border]: !title })} key={index}>
						{!!title && <div className={styles.title}>{title}</div>}
						{items.map((item) => {
							const itemLabel = typeof item === "string" ? item : item.label;
							const itemValue = typeof item === "string" ? item : item.value;
							const isDismissed = !!value && value !== itemValue;
							const isSelected = !!value && value === itemValue;
							return (
								<button
									key={itemValue}
									value={itemValue}
									onClick={onClick}
									ref={(el) => (buttonRefs.current[itemValue] = el)}
									className={cx(styles.item, {
										[styles.selected]: isSelected,
										[styles.dismissed]: isDismissed,
									})}
								>
									{itemLabel}
								</button>
							);
						})}
					</div>
				))}
			</div>
			{!!controls && <div className={styles.controls}>{controls}</div>}
		</div>
	);
}
