import { HTMLProps, useEffect } from "react";
import { useManualPassIdInput } from "../utils/useManualPassIdInput";
import { useNavigate } from "react-router-dom";
import { CFHOF_KIOSK_ROUTE } from "../config";
import styles from "./PassIDDebug.module.scss";

type PassIDDebugProps = {
	passIdState: "pending" | "accepted";
} & HTMLProps<HTMLDivElement>;

export default function PassIDDebug({
	passIdState,
	...props
}: PassIDDebugProps) {
	const navigate = useNavigate();
	const { userInputPassId, debouncedTouchPassId, cycleThroughPassId } =
		useManualPassIdInput();

	useEffect(() => {
		if (!debouncedTouchPassId) return;
		navigate(`${CFHOF_KIOSK_ROUTE}?scannedPassId=${debouncedTouchPassId}`);
	}, [debouncedTouchPassId, navigate]);
	return (
		<div
			{...props}
			className={styles.main}
			onClick={() => cycleThroughPassId()}
		>
			{!!userInputPassId && (
				<span
					style={{
						color: passIdState === "accepted" ? "green" : "yellow",
					}}
				>
					{userInputPassId}
				</span>
			)}
		</div>
	);
}
