import { createContext, useRef, useState, useEffect, useContext } from "react";
import Capture from "../utils/Capture";

const CaptureContext = createContext({});

export default CaptureContext;

export function CaptureHandler({ children }) {
	const capture = useRef(new Capture());
	const [init, setInit] = useState(false);

	useEffect(() => {
		capture.current.init().then(() => {
			setInit(true);
		});
	}, []);

	return (
		<CaptureContext.Provider
			value={{
				init: init,
				captureImage: capture.current.captureImage,
				getStream: capture.current.getStream,
				getVideo: capture.current.getVideo,
			}}
		>
			{children}
		</CaptureContext.Provider>
	);
}

export function useCapture() {
	return useContext(CaptureContext);
}
