import {
	PropsWithChildren,
	createContext,
	useCallback,
	useContext,
} from "react";
import { callRemoteApi } from "../utils/callRemoteApi";
import { callRemote } from "../utils/callRemote";
import { useSession } from "./SessionContext";
import logger from "../utils/logger";
import { useKioskCredential } from "./AppContext";

type ApiInterface = {
	uploadImage: (name: string, imageData: Blob) => Promise<void>;
	submitAnswer: (index: number, answer: string) => Promise<void>;
	submitAnswers: (answers: string[]) => Promise<void>;
};

const ApiContext = createContext<ApiInterface>({} as ApiInterface);

export default ApiContext;

type ApiProps = {} & PropsWithChildren;

export function ApiHandler({ children }: ApiProps) {
	const { captureJobs, userCredential } = useSession();
	const { kioskCredential } = useKioskCredential();

	const uploadImage = useCallback(
		async (name: string, imageData: Blob) => {
			if (!captureJobs) return;
			const { images } = captureJobs;
			const job = images.find((job) => job.settings.image === name);

			if (!job) return;
			const {
				settings: { upload_url: uploadUrl },
			} = job;

			const uploadResult = await callRemote(uploadUrl, {
				method: "PUT",
				body: await imageData.arrayBuffer(),
				headers: {
					"x-ms-blob-type": "BlockBlob",
					"Content-Type": "image/jpeg",
				},
			});

			if (!uploadResult.ok) throw uploadResult.value;
			logger.debug(
				`ApiHandler::uploadImage name=${name} uploadResult=${uploadResult.value}`
			);

			const url = new URL(uploadUrl);
			const { kioskId, venueId, apiToken } = kioskCredential;

			const apiResult = await callRemoteApi(
				`/job/done`,
				{
					method: "PATCH",
					body: JSON.stringify({
						jobId: job.id,
						workerType: "image_capture",
						workerId: `Kiosk ${kioskId}`,
						runtime: "kiosk",
						value: `azure://${url.pathname.replace("/", "")}`,
					}),
				},
				`${venueId}:${apiToken}`
			);

			if (!apiResult.ok) throw apiResult.value;
			logger.debug(
				`ApiHandler::uploadImage name=${name} apiResult=${apiResult.value}`
			);
		},
		[captureJobs, kioskCredential]
	);

	const submitAnswer = useCallback(
		async (index: number, answer: string) => {
			if (!captureJobs || !userCredential) return;

			const { questions } = captureJobs;
			const job = questions[index];

			const { kioskId, venueId } = kioskCredential;
			const { passId } = userCredential;
			const apiResult = await callRemoteApi(
				`/capture/job/done?venueId=${venueId}&passId=${passId}`,
				{
					method: "PATCH",
					body: JSON.stringify({
						jobId: job.id,
						workerType: "question_capture",
						workerId: kioskId ? `Kiosk ${kioskId}` : "Mobile",
						runtime: kioskId ? "kiosk" : "mobile",
						value: answer,
					}),
				}
			);

			if (!apiResult.ok) throw apiResult.value;
			logger.debug(
				`ApiHandler::submitAnswer questionId=${index} apiResult=${apiResult.value}`
			);
		},
		[captureJobs, kioskCredential, userCredential]
	);

	const submitAnswers = useCallback(
		async (answers: string[]) => {
			if (!captureJobs || !userCredential) return;

			const { questions } = captureJobs;
			const jobIds = questions.map((question) => question.id);
			const { kioskId } = kioskCredential;
			const { passId, venueId } = userCredential;
			const apiResult = await callRemoteApi(
				`/capture/jobs/done?venueId=${venueId}&passId=${passId}`,
				{
					method: "PATCH",
					body: JSON.stringify({
						jobIds,
						values: answers,
						workerType: "question_capture",
						workerId: kioskId ? `Kiosk ${kioskId}` : "Mobile",
						runtime: kioskId ? "kiosk" : "mobile",
					}),
				}
			);

			if (!apiResult.ok && passId.indexOf("TEST") !== 0) throw apiResult.value;
			logger.debug(`ApiHandle::submitAnswers apiResult=${apiResult.value}`);
		},
		[captureJobs, kioskCredential, userCredential]
	);

	return (
		<ApiContext.Provider
			value={{
				uploadImage,
				submitAnswer,
				submitAnswers,
			}}
		>
			{children}
		</ApiContext.Provider>
	);
}

export function useApi() {
	return useContext(ApiContext);
}
