import {
	createContext,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { fetchCaptureJobs } from "../utils/fetchCaptureJobs";
import { CaptureJobs, UserCredential } from "../types";
import { useLocalStorage } from "@uidotdev/usehooks";
import { initialUserCredential } from "../config";
import { useHomePage } from "../utils/useHomePage";
import { useTransition } from "./TransitionContext";
import logger, { setLoggerContext } from "../utils/logger";
import { useKioskCredential } from "./AppContext";

type SessionInterface = {
	userCredential?: UserCredential;
	captureJobs?: CaptureJobs;
	logIn: (
		credential: UserCredential,
		onMobile?: boolean
	) => Promise<Pick<SessionInterface, "userCredential" | "captureJobs">>;
	logOut: () => void;
	sessionTime: number;
	setSessionTime: (value: number) => void;
};

const SessionContext = createContext<SessionInterface>({} as SessionInterface);

export default SessionContext;

type SessionProps = {} & PropsWithChildren;

export function SessionHandler({ children }: SessionProps) {
	const [userCredential, setUserCredential] = useLocalStorage(
		"userCredential",
		initialUserCredential
	);
	const [captureJobs, setCaptureJobs] =
		useState<SessionInterface["captureJobs"]>();
	const [sessionTime, setSessionTime] = useState(Date.now());
	const {
		kioskCredential: { apiToken },
	} = useKioskCredential();

	const logIn = useCallback(
		async (credential: UserCredential, onMobile = false) => {
			const { venueId, passId } = credential ?? {};
			if (!venueId || !passId) throw new Error("Missing credential");
			setLoggerContext({ passId });
			const captureJobs = await fetchCaptureJobs(passId, venueId, apiToken);
			setCaptureJobs(captureJobs);
			setUserCredential((prevCredential) => {
				if (!onMobile)
					logger.info(
						`SessionContext::logIn set user credential, passId=${credential.passId}`
					);
				if (JSON.stringify(prevCredential) === JSON.stringify(credential))
					return prevCredential;
				return credential;
			});

			return { passId, captureJobs };
		},
		[apiToken, setUserCredential]
	);

	const logOut = useCallback(() => {
		setLoggerContext({ passId: undefined });
		setCaptureJobs(undefined);
		setUserCredential((prevState) => {
			if (prevState.passId)
				logger.info(
					`SessionContext::logOut clear user credential, passId=${prevState.passId}`
				);
			return { venueId: "", passId: "" };
		});
	}, [setUserCredential]);

	const homePage = useHomePage();
	const transition = useTransition();
	useEffect(() => {
		if (homePage.current || captureJobs) return;
		const { venueId, passId } = userCredential || {};
		if (!venueId || !passId) return transition(homePage.route);
		logIn(userCredential);
	}, [captureJobs, homePage, logIn, transition, userCredential]);

	return (
		<SessionContext.Provider
			value={{
				setSessionTime,
				userCredential,
				captureJobs,
				logIn,
				logOut,
				sessionTime,
			}}
		>
			{children}
		</SessionContext.Provider>
	);
}

export function useSession() {
	return useContext(SessionContext);
}
