import { useState } from "react";
import Controls from "./Controls";
import Layout from "./shared/Layout";
import TextSelect, { TextSelectProps } from "./shared/TextSelect";
import TextGrid, { TextGridProps } from "./shared/TextGrid";
import TextList, { TextListProps } from "./shared/TextList";
import useMatchMedia from "../../utils/useMatchMedia";
import { useQuestions } from "../../contexts/QuestionContext";

type SelectProps = {
	index: number;
	label: string;
	options:
		| TextSelectProps["options"]
		| TextGridProps["options"]
		| TextListProps["options"];
};

export default function Select({
	label,
	options,
	index,
	...inputProps
}: SelectProps) {
	const { answers, saveAnswer } = useQuestions();
	const [value, setValue] = useState<string>(answers[index] || "");
	const useTextGrid = options.length > 5;
	const isLargeScreen = useMatchMedia("(min-width: 1280px)");
	const controls = (
		<Controls
			canProceed={!!value}
			onNext={(next) => {
				saveAnswer(index, value);
				next();
			}}
		/>
	);

	return (
		<>
			{!useTextGrid && (
				<Layout label={label} controls={controls}>
					<TextSelect
						{...inputProps}
						options={options as TextSelectProps["options"]}
						value={value}
						onClick={(event) => setValue(event.currentTarget.value)}
					/>
				</Layout>
			)}
			{useTextGrid && isLargeScreen && (
				<TextGrid
					{...inputProps}
					label={label}
					controls={controls}
					options={options as TextGridProps["options"]}
					value={value}
					onClick={(event) => setValue(event.currentTarget.value)}
				/>
			)}

			{useTextGrid && !isLargeScreen && (
				<TextList
					{...inputProps}
					label={label}
					controls={controls}
					options={options as TextListProps["options"]}
					value={value}
					onClick={(event) => setValue(event.currentTarget.value)}
				/>
			)}
		</>
	);
}
