import {
	useEffect,
	useState,
	createContext,
	useContext,
	useCallback,
} from "react";
import styles from "./TransitionContext.module.scss";
import { useNavigate } from "react-router-dom";

const def = [false, () => {}];
const TransitionContext = createContext(def);
export default TransitionContext;

export function TransitionHandler({ children }) {
	const [context, setContext] = useState(false);
	useEffect(() => {
		if (context) {
			setTimeout(() => {
				setContext(false);
			}, 2000);
		}
	}, [context]);

	return (
		<TransitionContext.Provider value={[context, setContext]}>
			{children}
			<div
				onClick={() => setContext((_) => !_)}
				className={styles.main + " " + (context ? styles.enter : "")}
			>
				<div className={styles.inner}>
					<svg
						className={styles.svg}
						viewBox="0 0 2979.16 1080"
						preserveAspectRatio="none"
					>
						<path d="M2979.16,0l-623.54,1080H0L623.54,0H2979.16Z" />
					</svg>
				</div>
			</div>
		</TransitionContext.Provider>
	);
}

export function useTransition() {
	const navigate = useNavigate();
	const [, setContext] = useContext(TransitionContext);
	const transition = useCallback(
		(route) => {
			setContext(true);
			setTimeout(() => {
				navigate(route);
			}, 1000);
		},
		[navigate, setContext]
	);

	return transition;
}
