import { useState, useCallback, useRef, useEffect } from "react";
import { useSession } from "../contexts/SessionContext";
import { callRemoteApi } from "../utils/callRemoteApi";
import styles from "./MobileDeparture.module.scss";

const STATES = {
	PROCESSING: "Processing",
	CONTENT_READY: "Content Ready",
	FINAL_STAGES: "Final Stages",
};

// WARNING: Shortening the poll interval may require cancelling requests
// before making a new one. This is not implemented since the
// current polling interval is 1 minute.
const POLLING_INTERVAL = 1000 * 60; // 1 minute

const getAdjustedTimeEstimate = (timeEstimate) => {
	if (timeEstimate === null || timeEstimate === undefined)
		return STATES.PROCESSING;
	if (timeEstimate <= 0) return STATES.CONTENT_READY;
	if (timeEstimate <= 4) return STATES.FINAL_STAGES;
	return timeEstimate;
};

const TimeEstimateDisplay = ({ state }) => {
	switch (state) {
		case STATES.PROCESSING:
			return <span className={styles.processing}>{state}</span>;
		case STATES.CONTENT_READY:
			// WARNING: Text between span tags are for the AI text treatment. Do not remove.
			return (
				<span className={styles.stateText}>
					Content Re<span>ad</span>y
				</span>
			);
		case STATES.FINAL_STAGES:
			return <span className={styles.stateText}>{state}</span>;
		default:
			return (
				<div className={styles.minutes}>
					<span className={styles.minutesNumber}>
						{state.toString().padStart(2, "0")}
					</span>
					<span className={styles.minutesText}>Minutes To Go</span>
				</div>
			);
	}
};

const MobileDeparture = () => {
	const {
		userCredential: { passId, venueId },
	} = useSession();
	const [timeEstimate, setTimeEstimate] = useState(STATES.PROCESSING);
	const intervalRef = useRef(null);

	const clearPollingInterval = useCallback(() => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
	}, []);

	const getTimeEstimate = useCallback(async () => {
		try {
			const { ok, value } = await callRemoteApi(
				`/capture/departure?passId=${passId}&venueId=${venueId}`
			);

			if (!ok) {
				console.error(
					"Error getting time estimate",
					value.cause?.message || value.message
				);
				setTimeEstimate(STATES.PROCESSING);
			} else {
				const newTimeEstimate = getAdjustedTimeEstimate(value.timeEstimate);
				setTimeEstimate(newTimeEstimate);

				// If content is ready, clear the polling interval
				if (newTimeEstimate === STATES.CONTENT_READY) {
					clearPollingInterval();
				}
			}
		} catch (error) {
			console.error("Error in getTimeEstimate:", error);
			setTimeEstimate(STATES.PROCESSING);
		}
	}, [passId, venueId, clearPollingInterval]);

	useEffect(() => {
		// Initial call to get time estimate
		getTimeEstimate();

		// Set up interval for polling
		intervalRef.current = setInterval(getTimeEstimate, POLLING_INTERVAL);

		// Cleanup function
		return clearPollingInterval;
	}, [getTimeEstimate, clearPollingInterval]);

	useEffect(() => {
		const onVisibilityChange = () => {
			if (document.hidden) return;
			// NOTE: needs to add a `setTimeout` here to avoid Safar throws error about access control
			setTimeout(getTimeEstimate, 500);
		};
		window.addEventListener("pageshow", onVisibilityChange);
		window.addEventListener("focus", onVisibilityChange);
		window.addEventListener("visibilitychange", onVisibilityChange);

		return () => {
			window.removeEventListener("pageshow", onVisibilityChange);
			window.removeEventListener("focus", onVisibilityChange);
			window.removeEventListener("visibilitychange", onVisibilityChange);
		};
	}, [getTimeEstimate]);

	return (
		<div className={styles.main}>
			<div className={styles.topText}>We're busy creating your experience.</div>
			<div className={styles.bottomText}>
				<TimeEstimateDisplay state={timeEstimate} />
			</div>
		</div>
	);
};

export default MobileDeparture;
