import cx from "classnames";
import styles from "./Checkbox.module.scss";

function Checkbox({ checked, onChange, label, id, className }) {
	return (
		<span className={cx(styles.main, className)}>
			<input id={id} type="checkbox" checked={checked} onChange={onChange} />
			<label htmlFor={id} className={styles.inner}>
				<span className={styles.checkbox}></span>
				{label && <span className={styles.label}>{label}</span>}
			</label>
		</span>
	);
}

export default Checkbox;
