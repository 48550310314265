import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Modal from "./Modal";
import ButtonPlain from "../components/ButtonPlain";
import { useQuestions } from "../contexts/QuestionContext";
import { useHomePage } from "../utils/useHomePage";
import { useTransition } from "../contexts/TransitionContext";
import { useSetAppIdle } from "../contexts/AppContext";
import { useIdle } from "@uidotdev/usehooks";
import { inactivityTimeout } from "../config";

function InactivityWrapper() {
	const homePage = useHomePage();
	if (homePage.current) return null;
	return <IdleModal resetRoute={homePage.route} />;
}

function IdleModal({ resetRoute }) {
	const { resetStep } = useQuestions();
	const transition = useTransition();
	const isIdle = useIdle(inactivityTimeout);
	const setAppIdle = useSetAppIdle();

	const [isIdleModalOpen, setIsIdleModalOpen] = useState(false);

	useEffect(() => {
		setAppIdle(isIdle);
		isIdle && setIsIdleModalOpen(true);
	}, [isIdle, setAppIdle]);

	// Reset the app
	const handleModalClose = () => {
		setIsIdleModalOpen(false);
		resetStep();
		transition(resetRoute);
	};

	return (
		<>
			<Outlet />
			<Modal
				open={isIdleModalOpen}
				text="Are you there?"
				timer={20}
				onTimerComplete={handleModalClose}
			>
				<ButtonPlain onClick={() => setIsIdleModalOpen(false)}>
					I'm still here
				</ButtonPlain>
			</Modal>
		</>
	);
}

export default InactivityWrapper;
