import { FaceDetector, FilesetResolver } from "@mediapipe/tasks-vision";

class Face {
	rotation = { x: 0, y: 0, z: 0 };
	detectionData = {};
	runningMode = "VIDEO"; //"IMAGE" //
	rectSize = 1024;
	landmarkDetectTime = null;
	nocrop = false;
	videoSize = {
		width: 512,
		height: 512,
	};
	faceDetector = null;
	faceLandmarker = null;

	constructor(video) {
		this.streamImg = video;
		// this.init();
	}

	init = async () => {
		const { faceDetector } = await this.createFaceDetector();
		this.faceDetector = faceDetector;
	};

	createFaceDetector = async () => {
		const filesetResolver = await FilesetResolver.forVisionTasks(
			"https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm"
		);

		const faceDetector = await FaceDetector.createFromOptions(filesetResolver, {
			baseOptions: {
				modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_detector/blaze_face_short_range/float16/1/blaze_face_short_range.tflite`,
				delegate: "GPU",
			},
			runningMode: this.runningMode,
		});

		return { faceDetector };
	};

	faceDetect = () => {
		if (!this.faceDetector) return false;
		this.startTimeMs = performance.now();
		const detections = this.faceDetector.detectForVideo(
			this.streamImg,
			this.startTimeMs
		).detections;

		if (detections[0]) {
			const {
				originX: x,
				originY: y,
				width,
				height,
			} = detections[0].boundingBox;

			this.detectionData = {
				videoHeight: this.streamImg.videoHeight,
				videoWidth: this.streamImg.videoWidth,
				x,
				y,
				width,
				height,
			};

			return this.detectionData;
		}

		return false;
	};

	getDetectionData = () => {
		return this.detectionData;
	};

	update = () => {};
}

export default Face;
