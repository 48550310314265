import { useEffect } from "react";
import { useSession } from "../contexts/SessionContext";

export function useLogOut(scannedPassId?: string) {
	const { logOut } = useSession();

	useEffect(() => {
		if (scannedPassId) return;
		logOut();
	}, [scannedPassId, logOut]);
}
