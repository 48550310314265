import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { useSession } from "../contexts/SessionContext";
import styles from "./MobileLanding.module.scss";
import { findNextRoute } from "../utils/findNextRoute";
import { useTransition } from "../contexts/TransitionContext";
import { nl2br } from "../utils/nl2br";
import { useAppMode } from "../contexts/AppContext";

export default function MobileLanding() {
	const { captureJobs, logIn } = useSession();
	const transition = useTransition();
	const [section, setSection] = useState<"initial" | "kiosk-only">("initial");
	const appMode = useAppMode();

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const venueId = searchParams.get("venueId");
		const passId = searchParams.get("passId");

		if (!venueId || !passId) return;

		logIn({ venueId, passId }, true);
	}, [logIn]);

	useEffect(() => {
		if (!captureJobs) return;
		const route = findNextRoute([appMode], captureJobs);
		if (route === "kiosk-only") {
			setSection(route);
			return;
		}

		const id = setTimeout(() => {
			transition(route);
		}, 1000);

		return () => clearTimeout(id);
	}, [appMode, captureJobs, transition]);

	return (
		<div className={styles.main}>
			{section === "initial" && <Loader size="lg" />}
			{section === "kiosk-only" && (
				<div className={styles.content}>
					<h1>
						{nl2br(
							`Please head to the kiosk to start\nthe GAME ON! AI experience.`
						)}
					</h1>
				</div>
			)}
		</div>
	);
}
