import styles from "./Button.module.scss";
import { useTransition } from "../contexts/TransitionContext";
import cx from "classnames";
import { HTMLProps, PropsWithChildren } from "react";

type ButtonProps = {
	href?: string;
} & PropsWithChildren &
	Pick<
		HTMLProps<HTMLButtonElement>,
		"onClick" | "className" | "disabled" | "placeholder"
	>;

function Button({
	children,
	href,
	onClick,
	disabled,
	placeholder,
	className,
}: ButtonProps) {
	const transition = useTransition();

	const handleClick = () => {
		if (href?.indexOf("http") === 0) return (window.location.href = href);
		transition(href);
	};

	return (
		<button
			className={cx(
				styles.button,
				{
					[styles.placeholder]: placeholder,
					[styles.disabled]: disabled,
				},
				className
			)}
			onClick={onClick || handleClick}
			disabled={disabled}
		>
			{children}
		</button>
	);
}

export default Button;
