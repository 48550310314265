import { useCallback, useState } from "react";
import Controls from "./Controls";
import styles from "./Text.module.scss";
import Layout from "./shared/Layout";
import TextInput from "./shared/TextInput";
import { useQuestions } from "../../contexts/QuestionContext";
import useMatchMedia from "../../utils/useMatchMedia";

type TextProps = {
	index: number;
	type: string;
	label: string;
	suggestions?: string[];
	suggestionsLabel?: string;
};
export default function Input({
	type,
	label,
	index,
	suggestions,
	suggestionsLabel,
	...inputProps
}: TextProps) {
	const { answers, saveAnswer } = useQuestions();
	const [value, setValue] = useState<string>(answers[index] ?? "");
	const [valid, setValid] = useState(false);
	const isLargeScreen = useMatchMedia("(min-width: 1280px)");
	const onExampleSelect = useCallback((value: string) => {
		setValue(value);
		setValid(true);
	}, []);

	return (
		<Layout
			textInput={true}
			label={label}
			examples={isLargeScreen ? suggestions : []}
			examplesLabel={suggestionsLabel}
			onExampleSelect={onExampleSelect}
			controls={
				<Controls
					className={styles.controls}
					canProceed={valid}
					onNext={(next) => {
						saveAnswer(index, value);
						next();
					}}
					examples={!isLargeScreen ? suggestions : []}
					examplesLabel={suggestionsLabel}
					onExampleSelect={onExampleSelect}
				/>
			}
		>
			<TextInput
				{...inputProps}
				type={type}
				value={value}
				forceValid={valid}
				onInput={(event) => setValue(event.currentTarget.value)}
				onValid={setValid}
			/>
		</Layout>
	);
}
