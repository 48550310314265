import Button from "../components/Button";
import styles from "./KioskOrMobile.module.scss";
import { nl2br } from "../utils/nl2br";
import { useState } from "react";
import { useKioskMode } from "../contexts/AppContext";
import { useFullScreen } from "../utils/useFullScreen";

export default function KioskOrMobile() {
	const [section, setSection] = useState<"prompt" | "mobile">("prompt");
	const kioskMode = useKioskMode();
	const onKioskClick = useFullScreen("../questionnaire");
	const onMobileClick = useFullScreen(() => setSection("mobile"));

	return (
		<div className={styles.main}>
			{section === "prompt" && (
				<div className={styles.content}>
					<h1>{nl2br(`Let’s get to know you\nwith a few questions.`)}</h1>
					<div className={styles.buttons}>
						{kioskMode === "either" && (
							<div className={styles.mobileSwitch} onClick={onMobileClick}>
								Switch to mobile
							</div>
						)}
						{kioskMode === "mobile" && (
							<Button onClick={onMobileClick}>Continue on mobile</Button>
						)}
						{(kioskMode === "either" || kioskMode === "kiosk") && (
							<Button onClick={onKioskClick}>
								{kioskMode === "either" ? "Continue on kiosk" : "Continue"}
							</Button>
						)}
					</div>
				</div>
			)}

			{section === "mobile" && (
				<div className={styles.content}>
					<h1>{nl2br(`Scan the QR code on\nthe back of your ticket.`)}</h1>
					<div className={styles.buttons}>
						<Button href="..">Done</Button>
					</div>
				</div>
			)}
		</div>
	);
}
