import { useCallback, useEffect, useState } from "react";
import Loader from "../components/Loader";
import styles from "./KioskRegister.module.scss";
import Button from "../components/Button";
import { useKioskCredential } from "../contexts/AppContext";
import { KioskCredential } from "../types";
import { callRemoteApi } from "../utils/callRemoteApi";
import { defaultVenueId } from "../config";

export default function KioskRegister() {
	const [section, setSection] = useState<"prompt" | "verify">("prompt");
	const [verifyStatus, setVerifyStatus] = useState<"initial" | "verifying">(
		"initial"
	);
	const [verifyError, setVerifyError] = useState<string>();
	const { setKioskCredential } = useKioskCredential();

	const [kioskCredentialInput, setKioskCredentialInput] =
		useState<KioskCredential>();

	const onVerifyClick = useCallback(async () => {
		const { venueId, apiToken, kioskId } = kioskCredentialInput || {};
		if (!venueId) return;
		try {
			setVerifyStatus("verifying");
			const result = await callRemoteApi(
				`/capture/mode?kioskId=${kioskId}`,
				{},
				`${venueId}:${apiToken}`
			);

			if (!result.ok)
				return setVerifyError(
					(result.value.cause as any)?.message || result.value.message
				);

			setKioskCredential({ apiToken, venueId, kioskId } as KioskCredential);
		} catch (error) {
			setVerifyError(
				error instanceof Error
					? (error.cause as any)?.message || error.message
					: JSON.stringify(error)
			);
		}
	}, [kioskCredentialInput, setKioskCredential]);

	const onRegisterClick = useCallback(async () => {
		let venueId: string = "",
			kioskId: string = "";

		while (!venueId) {
			venueId = prompt("Enter the venue ID:", defaultVenueId) || "";
		}

		while (!kioskId) {
			kioskId = prompt(`Enter Kiosk ID:`, "0") || "";
		}

		if (kioskId === "0")
			return setKioskCredential({
				venueId,
				kioskId,
				apiToken: "capture_prod_test_token",
			});

		const random = window.crypto.randomUUID().replaceAll("-", "");
		const apiToken = `ks${kioskId.padStart(2, "0")}_${random}`;

		setVerifyStatus("verifying");
		const result = await callRemoteApi(
			`/capture/mode?kioskId=${kioskId}`,
			{},
			`${venueId}:${apiToken}`
		);

		if (result.ok) return setKioskCredential({ venueId, kioskId, apiToken });
		setKioskCredentialInput({ venueId, kioskId, apiToken });
		setVerifyStatus("initial");
		setSection("verify");
	}, [setKioskCredential]);

	useEffect(() => {});

	return (
		<div className={styles.main}>
			<div className={styles.content}>
				{section === "prompt" && (
					<>
						{verifyStatus === "initial" && (
							<Button onClick={onRegisterClick as any}>Register kiosk</Button>
						)}

						{verifyStatus === "verifying" && (
							<Loader className={styles.loader} size="lg" />
						)}
					</>
				)}

				{section === "verify" && !verifyError && (
					<div>
						<div className={styles.copy}>
							Log in to the Admin panel and validate the below API Token
							<pre>"{kioskCredentialInput?.apiToken}"</pre>
						</div>

						{verifyStatus === "initial" && (
							<Button onClick={onVerifyClick as any}>Verify</Button>
						)}
						{verifyStatus === "verifying" && (
							<Loader className={styles.loader} size="lg" />
						)}
					</div>
				)}

				{section === "verify" && verifyError && (
					<div>
						<div className={styles.copy}>
							Something went wrong
							<pre>"{verifyError}"</pre>
						</div>

						<Button
							onClick={
								(() => {
									setVerifyError(undefined);
									setVerifyStatus("initial");
								}) as any
							}>
							Try Again
						</Button>
					</div>
				)}
			</div>
		</div>
	);
}
