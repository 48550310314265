import cx from "classnames";
import { HTMLProps, MouseEventHandler, ReactNode, useState } from "react";
import Head from "./Head";
import styles from "./TeamGrid.module.scss";

export type TeamGridProps = {
	label: string;
	value: string;
	options: { name: string; nickname: string; value: string; logo: string }[];
	onClick?: MouseEventHandler<HTMLButtonElement>;
	controls?: ReactNode;
};

export default function TeamGrid({
	options,
	label,
	onClick,
	controls,
	value,
	...inputProps
}: TeamGridProps) {
	return (
		<div className={styles.main}>
			<Head className={styles.head} label={label} />
			<div className={styles.teams}>
				{options.map((option, index) => {
					const { name, nickname, value: itemValue, logo } = option;
					const isSelected = !!value && value === itemValue;
					const isDismissed = !!value && value !== itemValue;

					return (
						<button
							className={cx(styles.item, {
								[styles.selected]: isSelected,
								[styles.dismissed]: isDismissed,
							})}
							key={index}
							value={itemValue}
							onClick={onClick}
						>
							<div className={styles.nickname}>{nickname || ""}</div>
							<div className={styles.shortname}>{name}</div>

							<div className={styles.logo}>
								<Image src={logo ? `/assets/logos/${logo}` : ""} />
							</div>
						</button>
					);
				})}
			</div>
			<div className={styles.mask}></div>
			{!!controls && <div className={styles.controls}>{controls}</div>}
		</div>
	);
}

function Image(props: HTMLProps<HTMLImageElement>) {
	const [opacity, setOpacity] = useState<0 | 1>(0);

	return (
		<img
			style={{ opacity }}
			className={styles.image}
			alt=""
			{...props}
			onLoad={() => setOpacity(1)}
		/>
	);
}
