import cx from "classnames";
import styles from "./Head.module.scss";

function Head({ className, label }) {
	return (
		<div className={cx(styles.main, className)}>
			<span>{label}</span>
		</div>
	);
}

export default Head;
