import { KioskCredential, UserCredential } from "./types";

export const apiUrl = process.env.REACT_APP_API_URL ?? fallbackOnDomain();
export const websocketUrl =
	process.env.REACT_APP_WEBSOCKET_URL ??
	apiUrl?.replace("api.", "websocket.").replace("http", "ws");

export const defaultVenueId = "271828";

export const initialUserCredential: UserCredential = (() => {
	const storedItem = localStorage.getItem("userCredential");
	return storedItem ? JSON.parse(storedItem) : { venueId: "", passId: "" };
})();

export const initialKioskCredential: KioskCredential = (() => {
	const storedItem = localStorage.getItem("kioskCredential");
	return storedItem
		? JSON.parse(storedItem)
		: { venueId: "", kioskId: "", apiToken: "" };
})();

export const CFHOF_KIOSK_ROUTE = "/cfhof/kiosk-zvrl";
export const CFHOF_MOBILE_ROUTE = "/cfhof/mobile-ebso";
export const inactivityTimeout = process.env.REACT_APP_INACTIVITY_TIMEOUT
	? parseInt(process.env.REACT_APP_INACTIVITY_TIMEOUT, 10)
	: 1000 * 30;

export const logtailToken = (() => {
	if (typeof window === "undefined") return;
	const location = window.location.href;
	return location.indexOf("hyperengine.xyz") > 0
		? "XB29NPTi3vHbxDzo3ZyRXdxz"
		: "oa8LVoLexPXeF2ESogtCyc5Q";
})();

function fallbackOnDomain() {
	if (typeof window === "undefined") return;
	const location = window.location.href;
	return location.indexOf("hyperengine-lab") > 0
		? "https://api.hyperengine-lab.xyz"
		: "https://api.hyperengine.xyz";
}
