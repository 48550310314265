import { HTMLProps, useId } from "react";
import styles from "./Checkbox.module.scss";

export type CheckboxProps = Pick<
	HTMLProps<HTMLInputElement>,
	"checked" | "onChange"
> & {
	label: string;
	value: string;
};
export default function Checkbox({ label, ...inputProps }: CheckboxProps) {
	const checkboxId = useId();

	return (
		<div className={styles.checkbox}>
			<input id={checkboxId} {...inputProps} type="checkbox" />
			<label htmlFor={checkboxId} className={styles.checkboxLabel}>
				<span className={styles.checkboxLabel_a}></span>
				<span className={styles.checkboxLabel_b}>{label}</span>
			</label>
		</div>
	);
}
