import { AppMode, CaptureJobs } from "../types";

/**
 * Find the next route based on app mode and capture jobs.
 *
 * @param {[AppMode]} systemMode - The current system modes.
 * @param {CaptureJobs} captureJobs - The capture jobs to check.
 * @param {boolean} [skipImageCheck=false] - Whether to skip image check.
 * @returns {string} The next route.
 */
export function findNextRoute(
	systemMode: [AppMode],
	captureJobs: CaptureJobs,
	skipImageCheck: boolean = false
): string {
	const [appMode] = systemMode;
	const { images, questions } = captureJobs;
	const completedImageJobs = images
		.filter((image) => {
			const { settings } = image;
			return ["front-on.1", "front-on.2", "front-on.3", "smile"].includes(
				settings.image
			);
		})
		.every((image) => image.status !== "queued");
	const completedQuestionJobs = questions.every(
		(question) => question.status !== "queued"
	);

	const answeredQuestions = questions.every((question) => !!question.value);

	if (appMode === "kiosk") {
		if (!completedImageJobs && !skipImageCheck) return "instruction";
		// if questions have been answered but not marked as ready,
		// jump to `questionnaire` page for special handling
		if (answeredQuestions && !completedQuestionJobs) return "questionnaire";
		return !completedQuestionJobs ? "kiosk-or-mobile" : "confirmation";
	}

	if (appMode === "public") {
		if (!completedImageJobs) return "kiosk-only";
		return !completedQuestionJobs ? "questionnaire" : "departure";
	}

	return "";
}
