import cx from "classnames";
import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import styles from "./Capture.module.scss";
import { useCapture } from "../contexts/CaptureContext";
import { useFace } from "../contexts/FaceContext";
import { useAppMode } from "../contexts/AppContext";
import { useSession } from "../contexts/SessionContext";
import { useTransition } from "../contexts/TransitionContext";
import { findNextRoute } from "../utils/findNextRoute";
import { useApi } from "../contexts/ApiContext";
import { useDebounce } from "@uidotdev/usehooks";

export default function Capture() {
	const [captureStep, setCaptureStep] = useState(1);
	const [inFrame, setInFrame] = useState(false);
	const debouncedInFrame = useDebounce(inFrame, 500);

	return (
		<div className={styles.main}>
			<div className={styles.heading}>
				<h1>
					{debouncedInFrame ? "HOLD STILL!" : "LINE UP YOUR FACE IN THE OVAL"}
				</h1>
			</div>
			<div className={styles.action}>
				<h2>
					{debouncedInFrame
						? `${captureStep}/4`
						: "USE ARROWS BELOW to adjust screen height"}
				</h2>
				<svg
					style={{ opacity: debouncedInFrame ? 0 : 1 }}
					width="24"
					height="54"
					viewBox="0 0 24 54"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M12 0L22.3923 18H1.6077L12 0Z" fill="white" />
					<path d="M12 54L22.3923 36H1.6077L12 54Z" fill="white" />
				</svg>
			</div>
			<CaptureCanvas
				inFrame={inFrame}
				onFrameChange={setInFrame}
				captureStep={captureStep}
				onCaptureStepChange={setCaptureStep}
			/>
		</div>
	);
}

type CaptureCanvasProps = {
	inFrame: boolean;
	onFrameChange: (value: boolean) => void;
	captureStep: number;
	onCaptureStepChange: Dispatch<SetStateAction<number>>;
};
export function CaptureCanvas({
	inFrame,
	onFrameChange,
	captureStep,
	onCaptureStepChange,
}: CaptureCanvasProps) {
	const { getStream, init, captureImage } = useCapture() as {
		captureImage: (name: string, faceData: {}) => Promise<Blob>;
		getStream: () => any;
		init: boolean;
	};
	const { faceDetect, initialized } = useFace() as {
		faceDetect: { current: () => any };
		initialized: boolean;
	};
	const videoRef = useRef<HTMLVideoElement>(null);
	const captureBlobs = useRef<Blob[]>(new Array(4));
	const { uploadImage } = useApi();
	const gotoNextRoute = useNextRoute();

	const onOvalAnimationEnd = useCallback(
		async (step: number) => {
			if (step === 4) {
				gotoNextRoute();

				const names = [
					"front-on.1",
					"front-on.2",
					"front-on.3",
					"smile",
				].entries();

				for (const [index, name] of names) {
					const blob = captureBlobs.current[index];
					await uploadImage(name, blob).catch(console.error);
				}
				return;
			}

			onCaptureStepChange((step) => step + 1);
		},
		[gotoNextRoute, onCaptureStepChange, uploadImage]
	);
	const onFlashAnimationStart = useCallback(
		(step: number) => {
			const faceData = faceDetect.current() || {
				videoHeight: 1080,
				videoWidth: 1440,
				x: 549,
				y: 436,
				width: 345,
				height: 345,
			};
			captureImage(`face-${step}`, faceData)
				.then((blob) => (captureBlobs.current[step - 1] = blob))
				.catch(console.error);
		},
		[captureImage, faceDetect]
	);

	useEffect(() => {
		if (!init || !videoRef.current) return;
		const stream = getStream();
		videoRef.current.srcObject = stream;
	}, [getStream, init]);

	useEffect(() => {
		let af: number;

		const checkInFrame = () => {
			if (!faceDetect.current && !initialized) return;
			const data = faceDetect.current();
			if (!data) {
				onFrameChange(false);
				return (af = window.requestAnimationFrame(checkInFrame));
			}
			const { x, y, width, height, videoHeight, videoWidth } = data;
			const cy = (y + height / 2) / videoHeight;
			const cx = (x + width / 2) / videoWidth;
			const inFrame = Math.abs(cy - 0.55) < 0.06 && Math.abs(cx - 0.5) < 0.05;
			onFrameChange(inFrame);
			af = window.requestAnimationFrame(checkInFrame);
		};

		af = window.requestAnimationFrame(checkInFrame);

		return () => {
			window.cancelAnimationFrame(af);
		};
	}, [faceDetect, initialized, onFrameChange]);

	return (
		<div className={styles.canvas}>
			<svg viewBox="0 0 580 460" className={styles.guide}>
				<ellipse cx="290" cy="230" rx="270" ry="210" fill="none" />
			</svg>
			<svg viewBox="0 0 1579 986" className={styles.veil}>
				<rect
					width="1579"
					height="986"
					fill="black"
					fillOpacity="0.9"
					mask="url(#hole)"
				/>
				<mask id="hole">
					<rect width="1579" height="986" fill="white" />
					<ellipse
						cx="789.5"
						cy="493"
						rx="210"
						ry="270"
						fill="black"
						stroke="white"
					/>
				</mask>
			</svg>
			<video className={styles.video} ref={videoRef} muted autoPlay />
			{[1, 2, 3, 4].map((step, index) =>
				step === captureStep && inFrame ? (
					<FlashOval
						key={`flashOval-${index}`}
						onOvalAnimationEnd={onOvalAnimationEnd}
						onFlashAnimationStart={onFlashAnimationStart}
						step={step}
					/>
				) : null
			)}
		</div>
	);
}

type FlashOvalProps = {
	step: number;
	onOvalAnimationEnd: (step: number) => void;
	onFlashAnimationStart: (step: number) => void;
};

function FlashOval({
	step,
	onOvalAnimationEnd,
	onFlashAnimationStart,
}: FlashOvalProps) {
	return (
		<>
			<svg
				viewBox="0 0 580 460"
				className={cx(styles.progress)}
				onAnimationEnd={() => onOvalAnimationEnd(step)}
			>
				<ellipse cx="290" cy="230" rx="270" ry="210" fill="none" />
			</svg>
			<div
				className={styles.flash}
				onAnimationStart={() => onFlashAnimationStart(step)}
			/>
		</>
	);
}

function useNextRoute() {
	const appMode = useAppMode();
	const { captureJobs } = useSession();
	const transition = useTransition();

	return useCallback(() => {
		if (!captureJobs) return;
		const next = findNextRoute([appMode], captureJobs, true);
		transition(`../${next}`);
	}, [appMode, captureJobs, transition]);
}
