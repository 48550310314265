import styles from "./Button.module.scss";
import cx from "classnames";

function ButtonPlain({
	children,
	onClick = undefined,
	disabled = false,
}) {


	return (
		<button
			className={cx(styles.button, {
				[styles.disabled]: disabled,
			})}
			onClick={onClick}
			disabled={disabled}>
			{children}
		</button>
	);
}

export default ButtonPlain;
