export const ANIMATION_FADE_UP_SPRING = {
	enter: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 0.125,
			type: "spring",
			damping: 25,
			stiffness: 500,
		},
	},
	exit: { opacity: 0, y: "5%", transition: { duration: 0.125 } },
};

export const ANIMATION_FADE_DOWN_SPRING = {
	enter: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 0.1,
			type: "spring",
			damping: 25,
			stiffness: 500,
		},
	},
	exit: { opacity: 0, y: "-5%", transition: { duration: 0.25 } },
};

export const ANIMATION_FADE = {
	enter: { opacity: 1, transition: { duration: 0.25 } },
	exit: { opacity: 0, transition: { duration: 0.25 } },
};
