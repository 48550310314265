import { useLocation } from "react-router-dom";
import { useAppMode } from "../contexts/AppContext";
import { CFHOF_KIOSK_ROUTE, CFHOF_MOBILE_ROUTE } from "../config";

export function useHomePage() {
	const appMode = useAppMode();
	const location = useLocation();
	const homeRoute =
		appMode === "kiosk" ? CFHOF_KIOSK_ROUTE : CFHOF_MOBILE_ROUTE;

	return {
		route: homeRoute,
		current:
			appMode === "public" && location.pathname === CFHOF_KIOSK_ROUTE
				? true
				: homeRoute === location.pathname,
	};
}
