import { useCallback, useEffect, useRef, useState } from "react";
import { useApplyKioskMode, useKioskCredential } from "../contexts/AppContext";
import { useSession } from "../contexts/SessionContext";
import { CaptureJobs } from "../types";
import { throwOnTimeout } from "./throwOnTimeout";

export function useLogIn(scannePassId?: string) {
	const applyKioskMode = useApplyKioskMode();
	const { logIn, setSessionTime } = useSession();
	const {
		kioskCredential: { venueId, kioskId, apiToken },
	} = useKioskCredential();
	const [pending, setPending] = useState(false);
	const [error, setError] = useState<string | undefined>();
	const [data, setData] = useState<CaptureJobs | undefined>();
	const hasCalled = useRef(false);

	const resetState = useCallback(() => {
		setError(undefined);
		setData(undefined);
		setPending(false);
	}, []);

	useEffect(() => {
		if (!venueId || !kioskId || !apiToken || !scannePassId || hasCalled.current)
			return;
		hasCalled.current = true;
		setPending(true);

		Promise.race([
			logIn({ venueId, passId: scannePassId })
				.then(({ captureJobs }) => {
					setData(captureJobs);
					setSessionTime(Date.now());
				})
				.then(applyKioskMode),
			throwOnTimeout(90000),
		])
			.catch((error) => {
				setSessionTime(Date.now());
				setError(error);
			})
			.finally(() => {
				hasCalled.current = false;
				setPending(false);
			});
	}, [
		apiToken,
		applyKioskMode,
		kioskId,
		logIn,
		scannePassId,
		setSessionTime,
		venueId,
	]);

	return { pending, error, data, resetState };
}
