import styles from "./Loader.module.scss";
import cx from "classnames";
import { MouseEventHandler } from "react";

export interface LoaderProps {
	size?: "sm" | "base" | "lg";
	color?: "white" | "black" | "yellow";
	className?: string;
	onClick?: MouseEventHandler;
}

const Loader = ({
	className,
	size = "base",
	color = "white",
	onClick,
}: LoaderProps) => {
	return (
		<svg
			onClick={onClick}
			className={cx(styles.svg, className, styles[size], styles[color])}
			viewBox="0 0 32 32"
		>
			<circle
				className={styles.circle}
				cx="16"
				cy="16"
				r="14"
				fill="none"
				strokeWidth="4"
			></circle>
		</svg>
	);
};

export default Loader;
