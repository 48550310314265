import styles from "./Start.module.scss";
import start from "../assets/start.png";
import Button from "../components/Button";

function Start() {
	return (
		<div className={styles.main}>
			<div className={styles.heading}>
				<h1>REMOVE HATS AND SUNGLASSES</h1>
			</div>
			<div className={styles.image}>
				<img src={start} alt="" />
			</div>
			<div className={styles.action}>
				<h2>Next we’ll take 4 photos</h2>
				<Button href={"../capture"}>Got it!</Button>
			</div>
		</div>
	);
}

export default Start;
